<template>
    <tr>
        <template v-if="item.parent">
            <td colspan="999">
                <div class="d-flex gap-3 ps-4">
                    <img class="img-thumbnail object-fit-cover thumbnail-xs"
                         :src="item.part.main_image ? item.part.main_image : partPlaceholderImage" />
                    <div class="d-flex flex-column gap-1">
                        {{ item.part.label }}
                        <span>Qty Per Kit: {{ item.quantity }}</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-else>
            <td style="max-width: 200px;">
                <input type="hidden"
                       :name="`items[${index}][id]`"
                       :value="item.id" />
                <PartFormSelect
                    :name="`items[${index}][part]`"
                    v-model="item.part">
                </PartFormSelect>
                <SkuField v-if="typeof item.part !== 'undefined' && !isExistingPart"
                          v-model="item.newPartSKU"
                          :sku-check-url="skuCheckUrl"
                          :item-skus="itemSkus" />
            </td>
            <td class="text-center quantity">
                <input class="form-control text-center m-auto qty-input"
                       type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       placeholder="1"
                       :name="`items[${index}][quantity]`"
                       v-model="item.quantity" />
            </td>
            <td>
                <div class="d-flex w-100 justify-content-center">
                    <Toggle classes="text-center m-auto"
                            v-model="isPartUsed"
                            :name="`items[${index}][is_used]`"
                            :disabled="isExistingPart"></Toggle>
                </div>
            </td>
            <td>
                <i class="fas fa-times text-danger"
                   role="button"
                   @click="emit('removeItem', index)"></i>
            </td>
        </template>
    </tr>
</template>

<script setup>
    import {computed, ref, watch} from 'vue';
    import PartFormSelect from '@/components/admin/utilities/partFormSelect.vue';
    import SkuField from "@/components/admin/utilities/SkuField.vue";
    import Toggle from "@/components/admin/utilities/toggle.vue";

    const emit = defineEmits(['removeItem', 'update:unitNet', 'is-invalid']);
    const props = defineProps({
        item: Object,
        index: [String, Number],
        partsUrl: String,
        skuCheckUrl: {
            type: String,
            required: true,
        },
        itemSkus: {
            type: Array,
            default: () => [],
        },
    });

    const partPlaceholderImage = window.placeholderImage;
    const isExistingPart = computed(() => typeof props.item.part === 'object');
    const isPartUsed = ref(!!props.item.is_used ?? false);

    watch(() => isPartUsed.value, (newValue) => {
        props.item.is_used = newValue;
    }, { immediate: true });

    watch(() => props.item.part, (newValue) => {
        if (typeof newValue === 'object') {
            isPartUsed.value = newValue.is_used;
        } else {
            isPartUsed.value = false;
        }
    });
</script>
