<template>
    <div class="d-flex justify-content-between wizard-header position-relative">
        <div class="border-top spacer-line"></div>
        <template v-for="(step, i) in steps">
            <div :role="i != currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'"
                 :class="{
                     'd-flex flex-column align-items-center progress-number-wrapper': true,
                     'active': currentStep == i
                 }"
                 @click="attemptGoToStep(i)">
                <div class="progress-number-inner-wrapper">
                    <span class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number">
                        <i v-if="isAllPreviousStepsValid(i) && i != currentStep && (i < currentStep || steps[i].validate())"
                           class="fa fa-check text-flat"></i>
                        <span v-else>{{ i }}</span>
                    </span>
                </div>
                <span class="fs-xs text-center">{{ step.label }}</span>
            </div>
        </template>
    </div>


    <div class="mt-4 mb-3">
        <div v-show="currentStep == 1">
            <SupplierStep :routes="routes"
                          :stepsData="stepsData"
                          :extras="extras"
                          @stepValueChanged="(data) => setStepData('supplier', data)">
            </SupplierStep>
        </div>
        <div v-show="currentStep == 2">
            <ItemsStep :routes="routes"
                       :stepsData="stepsData"
                       :extras="extras"
                       @stepValueChanged="(data) => setStepData('items', data)">
            </ItemsStep>
        </div>
        <div v-show="currentStep == 3">
            <ExtraStep :routes="routes"
                       :stepsData="stepsData"
                       :extras="extras"
                       @stepValueChanged="(data) => setStepData('extra', data)">
            </ExtraStep>
        </div>
        <div v-show="currentStep == 4">
            <CustomerOrderStep :routes="routes"
                               :stepsData="stepsData"
                               :extras="extras"
                               @stepValueChanged="(data) => setStepData('orders', data)">
            </CustomerOrderStep>
        </div>
        <div v-if="currentStep == 5">
            <SummaryStep :routes="routes"
                         :extras="extras"
                         :stepsData="stepsData">
            </SummaryStep>
        </div>
    </div>

    <div class="pb-3">
        <div class="d-flex justify-content-end gap-2">
            <button v-if="currentStep > 1"
                    type="button"
                    class="btn btn-secondary"
                    @click="previousStep">Back</button>
            <button v-if="currentStep == Object.keys(steps).length"
                    type="button"
                    class="btn btn-primary"
                    :disabled="!steps[currentStep].validate()"
                    @click="requestComplete"
                    ref="submitButtonRef">Complete</button>
            <button v-else
                    type="button"
                    class="btn btn-primary"
                    :disabled="!steps[currentStep].validate()"
                    @click="nextStep">Next</button>
        </div>
        <div class="text-end mt-2">
            <div ref="errorContainerRef"></div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, ref, nextTick } from "vue";
    import debounce from 'lodash/debounce';
    import SupplierStep from "@/components/admin/supplierOrders/formWizard/supplierStep.vue";
    import ItemsStep from "@/components/admin/supplierOrders/formWizard/itemsStep.vue";
    import ExtraStep from "@/components/admin/supplierOrders/formWizard/extraStep.vue";
    import CustomerOrderStep from "@/components/admin/supplierOrders/formWizard/customerOrderStep.vue";
    import SummaryStep from "@/components/admin/supplierOrders/formWizard/summaryStep.vue";

    const props = defineProps({
        routes: Object,
        extras: Object,
    });

    let steps = {
        1: {
            label: 'Supplier',
            validate: function () {
                if (!stepsData.supplier) {
                    return false;
                }
                if (stepsData.supplier.supplier) {
                    return true;
                }
                if (stepsData.supplier.name) {
                    return true;
                }

                return false;
            }
        },
        2: {
            label: 'Items',
            validate: function () {
                if (!stepsData.items || !stepsData.items.items || stepsData.items.items.length == 0) {
                    return false;
                }
                return true;
            }
        },
        3: {
            label: 'Extra',
            validate: function () {
                return true;
            }
        },
        4: {
            label: 'Customer Orders',
            validate: function () {
                return true;
            }
        },
        5: {
            label: 'Summary',
            validate: function () {
                return true;
            }
        },
    };
    const currentStep = ref(1);
    const stepsData = reactive({});
    const submitButtonRef = ref(null);
    const errorContainerRef = ref(null);
    const form = document.getElementById('wizard_form');

    const previousStep = function () {
        currentStep.value--;
    }
    const nextStep = function () {
        currentStep.value++;
    }
    const attemptGoToStep = function (requestedStep) {
        if (requestedStep == currentStep.value) {
            return false;
        }
        if (!isAllPreviousStepsValid(requestedStep)) {
            return false;
        }
        currentStep.value = requestedStep;

    }

    const isAllPreviousStepsValid = function (stepNumber) {
        for (let i = 1; i < stepNumber; i++) {
            if (!steps[i].validate()) {
                return false;
            }
        }
        return true;
    }

    const setStepData = function (step, data) {
        stepsData[step] = data;
    }

    const requestComplete = function () {
        for (const [key, step] of Object.entries(steps)) {
            if (!step.validate()) {
                return false;
            }
        }

        completeWizard();
    }

    const completeWizard = debounce(ajaxSteps, 350);

    async function ajaxSteps() {
        let data = JSON.parse(JSON.stringify(stepsData));
        data.submitter = submitButtonRef.value;
        form.dispatchEvent(new CustomEvent('ajax-submit', {
            detail: data,
        }));
    }

    function getLastValidStepNumber() {
        for (let i = 1; i < Object.keys(steps).length; i++) {
            if (!steps[i].validate()) {
                return i;
            }
        }
        return Object.keys(steps).length;
    }


    nextTick(function () {
        currentStep.value = getLastValidStepNumber();
        form.ajaxForm({
            validationTarget: errorContainerRef.value,
        });
    });
</script>
