<template>
    <div v-if="mobileLayout"
         class="d-flex flex-column gap-2">
        <div v-for="(pick, key) in item.picking_lines"
             :key="key"
             :class="{ 'border-bottom pb-2 mb-2': showBorder }">
            <div class="d-flex gap-2">
                <input type="hidden"
                       :name="`items[${item.id}][id]`"
                       :value="item.id" />
                <input type="hidden"
                       :name="`items[${item.id}][picking_lines][${key}][quantity]`"
                       :value="pick.quantity" />
                <input type="hidden"
                       :name="`items[${item.id}][picking_lines][${key}][location]`"
                       :value="pick.location.value" />
                <img :src="item.part_image ? item.part_image : partPlaceholderImage"
                     alt="Part Image"
                     class="img-thumbnail thumbnail-xs object-fit-cover" />
                <partName :item="item"></partName>
                <div class="flex-grow-1">
                    <div class="d-flex justify-content-end">
                        <button type="button"
                                class="btn btn-sm btn-danger text-nowrap"
                                @click="emit('removePicking', key)">
                            <i class="fa fa-fw fa-trash me-2"></i>Remove
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="item.tractor_part" class="ps-4 ms-3 mt-2">
                <div class="d-flex align-items-center gap-1 text-muted">
                    <tractorPartDrawer v-if="item.tractor_part.quick_view_url"
                    :sourceUrl="item.tractor_part.quick_view_url"></tractorPartDrawer>
                    Dismantled from {{ item.tractor_part.tractor.name }}
                </div>
                <div v-if="item.tractor_part.location && item.tractor_part.location.name">
                    Located at: {{ item.tractor_part.location.name }}
                </div>
            </div>
            <div class="ps-4 ms-3 mt-2 d-flex justify-content-between">
                <div>Qty: {{ pick.quantity }}</div>
                <div>{{ pick.location.label }}</div>
            </div>
        </div>
    </div>
    <tr v-else
        v-for="(pick, key) in item.picking_lines"
        :key="key">
        <td>
            <input type="hidden"
                   :name="`items[${item.id}][id]`"
                   :value="item.id" />
            <input type="hidden"
                   :name="`items[${item.id}][picking_lines][${key}][quantity]`"
                   :value="pick.quantity" />
            <input type="hidden"
                   :name="`items[${item.id}][picking_lines][${key}][location]`"
                   :value="pick.location.value" />
            <div class="d-flex gap-2">
                <img :src="item.part_image ? item.part_image : partPlaceholderImage"
                     alt="Part Image"
                     class="img-thumbnail object-fit-cover"
                     style="width: 4rem; height: 4rem;" />
                <div class="d-flex flex-column gap-2">
                    <partName :item="item"></partName>
                    <div v-if="item.tractor_part">
                        <div class="d-flex align-items-center gap-1 text-muted">
                            <tractorPartDrawer v-if="item.tractor_part.quick_view_url"
                            :sourceUrl="item.tractor_part.quick_view_url"></tractorPartDrawer>
                            Dismantled from {{ item.tractor_part.tractor.name }}
                        </div>
                        <div v-if="item.tractor_part.location && item.tractor_part.location.name">
                            Located at: {{ item.tractor_part.location.name }}
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td class="text-center">
            {{ pick.quantity }}
        </td>
        <td class="text-center">
            {{ pick.location.label }}
        </td>
        <td>
            <div class="d-flex align-items-center justify-content-center">
                <i class="fas fa-times text-danger"
                   role="button"
                   @click="emit('remove-picking', key)"></i>
            </div>
        </td>
    </tr>
</template>

<script setup>
    import partName from '@/components/admin/parts/components/name.vue';
    import tractorPartDrawer from '@/components/admin/tractors/tractorPartDrawer.vue';

    const emit = defineEmits(['remove-picking']);
    const props = defineProps({
        item: Object,
        mobileLayout: {
            type: Boolean,
            default: false,
        },
        showBorder: {
            type: Boolean,
            default: false,
        }
    });

    const partPlaceholderImage = window.placeholderImage;

</script>
