<template>
    <div class="mb-4 form-group">
        <label :for="id"
               :class="{
                   'control-label': true,
                   'required': required
               }">
            {{ label }}
        </label>
        <input class="form-control hide-validation"
               type="text"
               :id="id"
               :required="required"
               :disabled="disabled"
               :name="inputName"
               v-model="_value"
               @input="validate">
        <template v-if="showValidation">
            <div v-if="validationProgress === -1 && errorMessage"
                 class="text-danger">
                {{ errorMessage }}
            </div>
            <div v-else-if="validationProgress === 0"
                 class="text-muted">
                <i class="fa fa-spin fa-spinner"></i> Checking email...
            </div>
        </template>
    </div>
</template>

<script setup>
    import { ref, computed, watch } from "vue";
    import debounce from 'lodash/debounce';
    import { useFetch } from "@/utilities/useFetch.js";

    const emit = defineEmits(['update:modelValue']);
    const props = defineProps({
        modelValue: String,
        label: {
            type: String,
            default: 'Email'
        },
        inputName: {
            type: String,
            default: 'email'
        },
        required: {
            type: Boolean,
            value: false,
        },
        disabled: {
            type: Boolean,
            value: false,
        },
        showValidation: {
            type: Boolean,
            value: true,
        },
        excludeId: Number | String,
        validationUrl: String,
    });

    // -1: Invalid, 0: In progress, 1: Valid
    const validationProgress = ref(1);
    const errorMessage = ref(null);
    const _value = ref(props.modelValue);
    const id = "email_" + Math.floor(Math.random() * Date.now()).toString(36);

    const requestAjaxValidation = debounce(ajaxValidate, 300);
    let fetchAbort, fetchAbortSignal;
    async function ajaxValidate(input) {
        if (!props.validationUrl) {
            endValidation();
            return;
        }

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            let url = new URL(props.validationUrl);
            url.searchParams.set('email', input.target.value);
            if (props.excludeId) {
                url.searchParams.set('exclude', props.excludeId);
            }
            const res = await useFetch(url, { signal: fetchAbortSignal });

            let response = await res.json();
            endValidation(response.valid ? null : 'The email has already been taken.');

        } catch (err) {
            console.error(err);
        }
    }

    const basicEmailRegex = new RegExp('.+@.+\\..+');
    function validate(input) {
        startValidation();
        if (!input.target.value) {
            endValidation()
            return;
        }

        // Very basic check to see if it's an email
        if (!basicEmailRegex.test(input.target.value)) {
            endValidation('The email is invalid.');
            return;
        }
        requestAjaxValidation(input);
    }

    function startValidation() {
        validationProgress.value = 0;
        errorMessage.value = null;
    }
    function endValidation(error = null) {
        if (error == null) {
            validationProgress.value = 1;
            emit('update:modelValue', _value.value);
        } else {
            validationProgress.value = -1;
            errorMessage.value = error;
        }

    }

</script>
