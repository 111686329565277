<template>
    <div class="mb-4">
        <DependantManufacturingSelect :routes="routes"
                                      @valueChanged="updateParentWithValues"
                                      :preselected-manufacturer="extras.preselected.manufacturer"
                                      :preselected-series="extras.preselected.series"
                                      :preselected-vehicle="extras.preselected.vehicle"
                                      ref="manufacturingSelectGroupRef">
        </DependantManufacturingSelect>

        <div class="table-responsive-xl">
            <table class="table table-bordered table-striped table-vcenter mb-0">
                <thead>
                    <tr>
                        <th>Part</th>
                        <th class="text-center">Qty</th>
                        <th class="text-center" width="125px">Item Used?</th>
                        <th style="width:2%"></th>
                    </tr>
                </thead>
                <tbody>
                    <Item v-for="( item, index ) in  lines"
                          :index="index"
                          :item="item"
                          :partsUrl="routes.parts"
                          :key="item.id"
                          :item-skus="itemSkus"
                          :sku-check-url="routes.sku_check"
                          @removeItem="removeLine">
                    </Item>
                </tbody>
            </table>
        </div>
        <button class="btn btn-sm btn-secondary mt-3"
                type="button"
                @click="addLine()">
            Add Item
        </button>
    </div>
    <div class="form-group">
        <label for="comments"
               class="control-label">Comments</label>
        <textarea class="form-control"
                  name="comments"
                  rows="5"
                  v-model="comments"
                  id="comments">
        </textarea>
    </div>
</template>

<script setup>
    import {computed, reactive, ref, watch} from "vue";
    import DependantManufacturingSelect from '@/components/admin/utilities/dependantManufacturingSelect.vue';
    import Item from '@/components/admin/quotes/draft/item.vue';
    import {useFetch} from "@/utilities/useFetch.js";

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });

    const lines = reactive(props.extras.preselected.items ? props.extras.preselected.items : []);
    const comments = ref(props.extras.preselected.comments ? props.extras.preselected.comments : '');
    const manufacturingSelectGroupRef = ref(null);
    const itemSkus = computed(() => lines.filter(l => l.newPartSKU).map(l => l.newPartSKU));

    const kitComponents = {};

    watch(
        () => ({ ...lines }),
        () => {
            loadMissingKitComponents();
            updateParentWithValues();
        },
        { deep: true }
    );

    watch(() => comments.value, () => updateParentWithValues());

    function addLine(lineObj = null, index = null) {
        if (lineObj === null) {
            lineObj = {
                id: Math.floor(Math.random() * Date.now()).toString(36),
                quantity: 1,
            };

        }
        if (index === null || index === -1) {
            lines.push(lineObj);
        } else {
            lines.splice(index, 0, lineObj);
        }

        return lineObj;
    }

    function removeLine(removeAtIndex) {
        let lineToRemove = lines[removeAtIndex];
        lines.splice(removeAtIndex, 1);
        if (kitComponents.hasOwnProperty(lineToRemove.id)) {
            // Loop in reverse and remove components
            for (var i = lines.length - 1; i >= 0; i--) {
                if (lines[i].parent === lineToRemove.id) {
                    lines.splice(i, 1);
                }
            }
            delete kitComponents[lineToRemove.id];
        }
    }


    const updateParentWithValues = function () {
        let stepData = [];
        for (const [key, line] of Object.entries(lines)) {
            let lineData = JSON.parse(JSON.stringify(line));
            if (!lineData.part) {
                continue;
            }
            if (typeof lineData.part === 'object') {
                delete lineData.newPartSKU;
            }
            stepData.push(lineData);
        }

        emit('stepValueChanged', {
            items: stepData,
            comments: comments.value,
            manufacturer: manufacturingSelectGroupRef.value && manufacturingSelectGroupRef.value.manufacturer ? { ...manufacturingSelectGroupRef.value.manufacturer } : null,
            series: manufacturingSelectGroupRef.value && manufacturingSelectGroupRef.value.series ? { ...manufacturingSelectGroupRef.value.series } : null,
            vehicle: manufacturingSelectGroupRef.value && manufacturingSelectGroupRef.value.vehicle ? { ...manufacturingSelectGroupRef.value.vehicle } : null,
        });
    }

    const loadMissingKitComponents = async function () {
        for (const [key, line] of Object.entries(lines)) {

            if (line.part && line.part.kit && !kitComponents.hasOwnProperty(line.id)) {
                kitComponents[line.id] = [];
                try {
                    const res = await useFetch(props.routes.kit_components.replace('%kit%', line.part.kit));
                    let components = await res.json();
                    if (Array.isArray(components)) {
                        components.reverse().forEach((component) => addKitComponentLine(line, component));
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }
    };

    const addKitComponentLine = function (kitLine, componentData) {
        let index = lines.map(line => line.id).indexOf(kitLine.id);
        let lineObj = {
            id: Math.floor(Math.random() * Date.now()).toString(36),
            quantity: componentData.quantity,
            is_used: componentData.is_used,
            part: componentData,
            parent: kitLine.id,
        };

        addLine(lineObj, index + 1);
        kitComponents[kitLine.id].push(lineObj.id);
    }

    if (lines.length > 0) {
        lines.forEach((line) => {
            if (line.parent) {
                if (kitComponents.hasOwnProperty(line.parent)) {
                    kitComponents[line.parent].push(line.id);
                } else {
                    kitComponents[line.parent] = [line.id];
                }
            }
        })
        updateParentWithValues();
    } else {
        // Add blank line to save user clicking "Add Item" initially
        addLine();
    }
</script>
