<template>
    <span>
        <template v-if="item.url">
            <span class="me-1">
                <partDrawer v-if="quickViewUrl" :sourceUrl="quickViewUrl"></partDrawer>
            </span>
            <a :href="item.url"
               target="_blank">
                {{ partName }}
            </a>
            <template v-if="partSku">
                <br />
                <span class="text-muted">SKU: {{ partSku }}</span>
            </template>
        </template>
        <template v-else>
            {{ partName }}
            <template v-if="partSku">
                <br />
                <span class="text-muted">SKU: {{ partSku }}</span>
            </template>
        </template>
    </span>
</template>

<script setup>
    import { ref, computed } from "vue";
    import partDrawer from "@/components/admin/parts/partDrawer.vue";

    const props = defineProps({
        item: Object,
    });
    const partSku = ref(null);
    const quickViewUrl = ref(null);
    const partName = computed(() => {
        if (typeof props.item.part === 'object') {
            const splitName = props.item.part.label.split('(');
            partSku.value = splitName[1].replace(')', '');
            quickViewUrl.value = props.item.part.quick_view_url;
            return splitName[0].trim();
        }
        partSku.value = null;
        return props.item.part;
    });
</script>
