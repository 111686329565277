<script setup>
import {AisSearchBox} from "vue-instantsearch/vue3/es";
import {useVModel} from "@vueuse/core";
import {computed, onMounted, ref} from "vue";

const emit = defineEmits([
    "update:modelValue",
    "reset",
    "shortcut-pressed",
]);
const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    darkMode: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: "Search...",
    },
    enableShortcut: {
        type: Boolean,
        default: false,
    },
});

const search = useVModel(props);
const inputEl = ref(null);

const searchFieldBackground = computed(() => props.darkMode ? 'var(--bs-darker)' : 'var(--bs-white)');

const isMac = navigator.userAgent.includes('Macintosh');

onMounted(() => {
    document.addEventListener('keydown', (event) => {
        if (! props.enableShortcut) {
            return;
        }

        if ((event.ctrlKey || (isMac && event.metaKey)) && event.key === 'k') {
            event.preventDefault();
            inputEl.value.focus();
            emit("shortcut-pressed");
        }
    });
});

function resetSearch(refine) {
    search.value = "";
    refine("");
    inputEl.value.focus();
    emit("reset");
}

defineExpose({
    inputEl,
});
</script>

<template>
    <AisSearchBox>
        <template #default="{ isSearchStalled, refine }">
            <div class="form-control position-relative p-1">
                <input
                    ref="inputEl"
                    type="text"
                    class="w-100 border-0 search-field pe-auto ps-3"
                    :class="darkMode ? 'text-light' : null"
                    :placeholder="placeholder"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    v-model="search"
                    @input="refine($event.target.value)"
                >
                <span v-if="isSearchStalled" class="position-absolute loading-text text-muted">Loading...</span>
                <button v-show="search !== ''" type="button" class="position-absolute clear-button btn btn-light btn-sm" :class="darkMode ? 'btn-dark' : null" @click="resetSearch(refine)">
                    <i class="fa-solid fa-times"></i>
                </button>
                <div v-if="enableShortcut" class="position-absolute text-muted shortcut-info pe-none user-select-none d-none d-lg-block">
                    <kbd v-if="isMac">&#8984;</kbd>
                    <kbd v-else>Ctrl</kbd> + <kbd>K</kbd>
                </div>
            </div>
        </template>
    </AisSearchBox>
</template>

<style scoped lang="scss">
.loading-text {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.clear-button {
    right: 0.12rem;
    top: 50%;
    transform: translateY(-50%);
}

.search-field {
    outline: none;
    background-color: v-bind(searchFieldBackground);
}

.shortcut-info {
    right: 3rem;
    top: 40%;
    transform: translateY(-50%);

    kbd {
        opacity: 0.5;
    }
}
</style>
