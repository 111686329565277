<script setup>
import {AisPagination} from "vue-instantsearch/vue3/es";
import {computed} from "vue";

const props = defineProps({
    navigationFn: {
        type: Function,
        default: null,
    },
});

const navigateToPage = computed(() => {
    return (refine, page) => {
        if (props.navigationFn) {
            props.navigationFn(refine, page);
            return;
        }

        refine(page);
    };
});
</script>

<template>
    <AisPagination>
        <template v-slot="{
            pages,
            currentRefinement,
            isFirstPage,
            isLastPage,
            createURL,
            refine,
            nbPages,
        }">
            <nav>
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{'disabled': isFirstPage}">
                        <a
                            class="page-link"
                            :href="createURL(0)"
                            aria-label="First page"
                            @click.prevent="navigateToPage(refine, 0)"
                        >
                            <i class="fa fa-angles-left"></i>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': isFirstPage}">
                        <a
                            class="page-link"
                            :href="createURL(currentRefinement - 1)"
                            aria-label="Previous page"
                            @click.prevent="navigateToPage(refine, currentRefinement - 1)"
                        >
                            <i class="fa fa-angle-left"></i>
                        </a>
                    </li>
                    <li
                        v-for="page in pages"
                        :key="page"
                        class="page-item"
                        :class="{
                            'active': currentRefinement === page,
                        }"
                    >
                        <a
                            class="page-link"
                            :href="createURL(page)"
                            @click.prevent="navigateToPage(refine, page)"
                        >
                            {{ page + 1 }}
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': isLastPage}">
                        <a
                            class="page-link"
                            :href="createURL(currentRefinement + 1)"
                            aria-label="Next page"
                            @click.prevent="navigateToPage(refine, currentRefinement + 1)"
                        >
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': isLastPage}">
                        <a
                            class="page-link"
                            :href="createURL(nbPages)"
                            aria-label="Last page"
                            @click.prevent="navigateToPage(refine, nbPages)"
                        >
                            <i class="fa fa-angles-right"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </template>
    </AisPagination>
</template>
