<template>
    <i class="text-primary"
       :class="{
           'fa fa-magnifying-glass': !isWaitingToOpen,
           'fa fa-spin fa-spinner': isWaitingToOpen,
       }"
       role="button"
       @click="openDrawer"></i>
    <drawer v-if="customerOrder"
            :title="'Order #' + customerOrder.id"
            size="lg"
            ref="drawerRef">
        <template #default>
            <div class="d-flex gap-3">
                <img class="img-thumbnail thumbnail-md object-fit-cover"
                     :src="customerOrder.main_image ? customerOrder.main_image : customerOrderPlaceholderImage" />
                <div>
                    <div>{{ customerOrder.customer.name }}</div>
                    <div>{{ customerOrder.customer.email }}</div>
                    <div>{{ customerOrder.customer.telephone }}</div>
                </div>
            </div>
            <div class="dl-tile mt-3">
                <dt>Delivery Address</dt>
                <dd>
                    <div v-if="customerOrder.delivery_address.line_1">{{ customerOrder.delivery_address.line_1 }}</div>
                    <div v-if="customerOrder.delivery_address.line_2">{{ customerOrder.delivery_address.line_2 }}</div>
                    <div v-if="customerOrder.delivery_address.line_3">{{ customerOrder.delivery_address.line_3 }}</div>
                    <div v-if="customerOrder.delivery_address.line_4">{{ customerOrder.delivery_address.line_4 }}</div>
                    <div v-if="customerOrder.delivery_address.town">{{ customerOrder.delivery_address.town }}</div>
                    <div v-if="customerOrder.delivery_address.county">{{ customerOrder.delivery_address.county }}</div>
                    <div v-if="customerOrder.delivery_address.postcode">{{ customerOrder.delivery_address.postcode }}</div>
                    <div v-if="customerOrder.delivery_address.country.name">{{ customerOrder.delivery_address.country.name }}</div>
                </dd>
            </div>
            <dl class="mt-3 d-grid gap-3"
                style="grid-template-columns: 1fr 1fr;">
                <div class="dl-tile">
                    <dt>Order Total</dt>
                    <dd>£{{ customerOrder.total_gross }}</dd>
                </div>
                <div class="dl-tile">
                    <dt>Delivery Cost</dt>
                    <dd>£{{ customerOrder.delivery_net }}</dd>
                </div>
                <div class="dl-tile">
                    <dt>Order Status</dt>
                    <dd v-html="customerOrder.status"></dd>
                </div>
            </dl>
            <table class="table mt-3">
                <thead>
                <tr>
                    <th>Item</th>
                    <th>SKU</th>
                    <th class="text-end">Quantity</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in customerOrder.items" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.sku }}</td>
                    <td class="text-end">{{ item.quantity }}</td>
                </tr>
                </tbody>
            </table>
        </template>
    </drawer>
</template>

<script setup>
import {nextTick, ref} from 'vue';
import {useFetch} from "@/utilities/useFetch.js";
import drawer from '@/components/admin/utilities/drawer.vue';

const customerOrderPlaceholderImage = window.placeholderImage;

const props = defineProps({
    customerOrderData: Object,
    sourceUrl: String,
});

const isWaitingToOpen = ref(false);
const isLoading = ref(false);
const hasFailed = ref(false);
const drawerRef = ref(null);
const customerOrder = ref(null);

const fetchData = async () => {
    if (isLoading.value || hasFailed.value) {
        return;
    }
    isLoading.value = true;
    const response = await useFetch(props.sourceUrl);
    if (!response.ok) {
        hasFailed.value = true;
        isLoading.value = false;
        return;
    }
    customerOrder.value = (await response.json()).data;
    isLoading.value = false;
    if (isWaitingToOpen.value) {
        isWaitingToOpen.value = false;
        nextTick(() => {
            // Need to be in nextTick because the drawerRef is not yet mounted as it depends on the v-if="customerOrder"
            openDrawer();
        });
    }
};

if (props.customerOrderData) {
    customerOrder.value = props.customerOrderData;
}

const openDrawer = () => {
    if (!customerOrder.value) {
        isWaitingToOpen.value = true;
        fetchData();
        return;
    }

    drawerRef.value?.openDrawer();
};
</script>
