<template>
    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="part_name">Part</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="quantity"
                        class="text-center">Qty</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="customer_order_id">Order</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="placed_at"
                        class="text-center">Placed</sortableTh>
        </template>
        <template #rows="{ data }">
            <tr v-for="row in data"
                :key="row.id">
                <td>
                    <div>
                        <partDrawer v-if="row.part.quick_view_url" :sourceUrl="row.part.quick_view_url"></partDrawer>
                        {{ row.part.name }}
                    </div>
                    <div v-if="row.part.sku" class="text-muted">SKU: {{ row.part.sku }}</div>
                </td>
                <td class="text-center">{{ row.quantity }}</td>
                <td>
                    <span v-if="row.customer_order_quick_link" class="me-1">
                        <CustomerOrderDrawer :source-url="row.customer_order_quick_link"></CustomerOrderDrawer>
                    </span>
                    <span v-html="row.customer_order_link"></span>
                </td>
                <td>{{ row.placed_at }}</td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import partDrawer from "@/components/admin/parts/partDrawer.vue";
    import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";
    import { ref } from "vue";

    const props = defineProps({
        ajaxUrl: String,
    });

    const orderBy = ref('placed_at');
    const orderAsc = ref(false);
    const limit = ref(10);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };
</script>
