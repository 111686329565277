<template>
    <div class="mb-4 form-group">
        <label for="expiry_delay"
               class="control-label required">Currency</label>
        <FormSelect v-model="currency"
                    :options="extras.currencies"
                    :url="routes.currencyUrl">
        </FormSelect>
        <span class="text-muted"
              v-if="defaultCustomerCurrency && currency.code !== defaultCustomerCurrency">
            Customer's default currency is {{ defaultCustomerCurrency }}.
        </span>
    </div>
    <div class="table-responsive">
        <table class="table table-bordered table-striped table-vcenter mb-0">
            <thead>
                <tr>
                    <th width="25%">Part</th>
                    <th class="text-center"
                        width="10%">Qty</th>
                    <th width="15%">Unit Price</th>
                    <th width="15%">Discount</th>
                    <th width="15%">VAT</th>
                    <th class="text-end"
                        width="15%">Line Price</th>
                    <th style="width:2%"></th>
                </tr>
            </thead>
            <tbody>
                <Item v-for="(item, index) in lines"
                      :index="index"
                      :item="item"
                      :currency="currency ? currency : { label: 'GBP (1.0000)', code: 'GBP', rate: 1, symbol: '£' }"
                      :partsUrl="routes.parts"
                      :taxRates="extras.taxRates"
                      :key="item.id"
                      :unitPriceEditable="unitPriceEditable"
                      :item-skus="itemSkus"
                      :sku-check-url="routes.sku_check"
                      @update:stockMessage="(newValue) => item.stock_message = newValue"
                      @update:unitNet="(newValue) => item.unit_net = newValue"
                      @update:discount="(newValue) => item.discount = newValue"
                      @removeItem="(index) => removeLine(index)">
                    <template v-if="item.is_delivery"
                              #item-description>
                        <div v-if="suggestedDeliveryPrice != null"
                             class="text-nowrap">
                            Suggested: {{ currency.symbol }}{{ suggestedDeliveryPrice * currency.rate }}

                            <i v-if="suggestedDeliveryBreakdown != null"
                               class="fas fa-info-circle swal-info"
                               data-info-title="Delivery Breakdown"
                               :data-info-html="suggestedDeliveryBreakdown"
                               role="button"></i>
                        </div>
                        <div v-else>
                            Suggested: N/A
                        </div>
                    </template>
                </Item>
            </tbody>
        </table>
    </div>
    <div class="text-muted"
         v-show="showAuthorisationWarning">
        Discounts over {{ extras.maxUnauthorisedDiscount }}% will require manager's approval
    </div>
    <div class="d-flex gap-2 mt-3">
        <button class="btn btn-sm btn-secondary"
                type="button"
                @click="addLine()">
            Add Item
        </button>
        <button class="btn btn-sm btn-secondary"
                type="button"
                :disabled="isDeliveryLineSet"
                @click="addDelivery">
            Add Delivery Charge
        </button>
    </div>
</template>

<script setup>
    import { reactive, ref, watch, computed } from "vue";
    import debounce from 'lodash/debounce';
    import { useFetch } from "@/utilities/useFetch.js";
    import { priceFormat } from "@/utilities/priceFormat.js";
    import FormSelect from '@/components/admin/utilities/formSelect.vue';
    import Item from '@/components/admin/customerOrders/components/item.vue';

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
        isInitialLoadingFinished: Boolean,
        unitPriceEditable: Boolean,
    });
    const lines = reactive(props.extras.preselected.items ? props.extras.preselected.items : []);
    const isDeliveryLineSet = ref(lines.filter((line) => line.is_delivery).length !== 0);
    const itemSkus = computed(() => lines.filter(l => l.newPartSKU).map(l => l.newPartSKU));

    const deliveryPostcode = ref(null);
    const deliveryCountry = ref(null);
    const deliveryParts = computed(() => lines.map(line => { return { part: line.part?.value, quantity: line.quantity } }).filter(part => part.part));
    const suggestedDeliveryPrice = ref(null);
    const suggestedDeliveryTax = ref(null);
    const suggestedDeliveryBreakdown = ref(null);
    watch([deliveryPostcode, deliveryCountry, deliveryParts], () => { updateSuggestedDeliveryPrice() });

    const kitComponents = {};
    const defaultCustomerCurrency = ref(null);
    const currency = ref(null);
    if (props.extras.preselected.currency) {
        currency.value = props.extras.currencies.find(currency => currency.code === props.extras.preselected.currency);
    }

    watch(
        () => ({ ...lines }),
        () => {
            loadMissingKitComponents();
            updateParentWithValues();
            isDeliveryLineSet.value = lines.filter((line) => line.is_delivery).length !== 0;
        },
        { deep: true }
    );

    watch(currency, () => {
        updateParentWithValues();
    });

    watch(props.stepsData, (newStepsData) => {
        if (newStepsData.addresses && newStepsData.addresses.delivery) {
            deliveryPostcode.value = newStepsData.addresses.delivery.postcode;
            deliveryCountry.value = newStepsData.addresses.delivery.country_code;
        }

        let newCurrencyCode = newStepsData.items?.currency?.code || newStepsData.customer?.defaultCurrencyCode;

        if (newCurrencyCode && newCurrencyCode !== currency.value?.code) {
            currency.value = props.extras.currencies.find(currency => currency.code === newCurrencyCode);
        }
        if (newStepsData.customer?.defaultCurrencyCode) {
            defaultCustomerCurrency.value = newStepsData.customer.defaultCurrencyCode;
        }
    });

    const showAuthorisationWarning = computed(() => {
        if (!Number.isInteger(props.extras.maxUnauthorisedDiscount)) {
            return false;
        }


        for (const [key, line] of Object.entries(lines)) {
            if (line.discount && line.discount > props.extras.maxUnauthorisedDiscount) {
                return true;
            }
        }
        return false;
    });

    let fetchAbort, fetchAbortSignal;
    const updateSuggestedDeliveryPrice = debounce(async function () {
        const saveResponse = function (response = null) {
            if (typeof response === 'object' && response !== null && response.price_found) {
                suggestedDeliveryPrice.value = priceFormat(response.price.toFixed(2));
                suggestedDeliveryTax.value = response.tax;
                // It will go into data attribute anyways, so build basic HTML as a string here.
                suggestedDeliveryBreakdown.value = `Address: ${response.address}<br/>Zone matched: ${response.delivery_zone}<br/>Band applied: ${response.delivery_band}`;
            } else {
                suggestedDeliveryPrice.value = null;
                suggestedDeliveryTax.value = null;
                suggestedDeliveryBreakdown.value = null;
            }
        }
        if (deliveryParts.value.length === 0 || props.routes.delivery_price === null || deliveryParts.length === 0 || deliveryCountry.value === null) {
            saveResponse();
            return;
        }

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            const res = await useFetch(props.routes.delivery_price, {
                signal: fetchAbortSignal,
                method: 'POST',
                body: {
                    'country': deliveryCountry.value,
                    'postcode': deliveryPostcode.value,
                    'parts': deliveryParts.value,
                }
            });

            saveResponse(await res.json());

        } catch (err) {
            saveResponse();
        }
    }, 750);

    function addLine(lineObj = null, index = null) {

        if (lineObj === null) {
            lineObj = {
                id: Math.floor(Math.random() * Date.now()).toString(36),
                quantity: 1,
                unit_net: 0,
                discount: 0,
            };

        }

        if (index === null) {
            index = lines.findIndex((line) => line.is_delivery);
        }

        if (index === -1) {
            lines.push(lineObj);
        } else {
            lines.splice(index, 0, lineObj);
        }

        return lineObj;
    }

    function addDelivery() {
        if (!isDeliveryLineSet.value) {
            lines.push({
                id: Math.floor(Math.random() * Date.now()).toString(36),
                part: 'Delivery Charge',
                quantity: 1,
                is_delivery: true,
                unit_net: suggestedDeliveryPrice.value !== null ? suggestedDeliveryPrice.value : 0,
                tax: suggestedDeliveryTax.value !== null ? suggestedDeliveryTax.value : null,
            });
        }
    }

    function removeLine(removeAtIndex) {
        let lineToRemove = lines[removeAtIndex];
        lines.splice(removeAtIndex, 1);
        if (kitComponents.hasOwnProperty(lineToRemove.id)) {
            // Loop in reverse and remove components
            for (var i = lines.length - 1; i >= 0; i--) {
                if (lines[i].parent === lineToRemove.id) {
                    lines.splice(i, 1);
                }
            }
            delete kitComponents[lineToRemove.id];
        }
    }


    const updateParentWithValues = function () {
        let stepData = [];
        for (const [key, line] of Object.entries(lines)) {
            let lineData = JSON.parse(JSON.stringify(line));
            if (!lineData.part && !lineData.is_delivery) {
                continue;
            }
            if (typeof lineData.part === 'object') {
                delete lineData.newPartSKU;
            }
            if (lineData.unit_net === null) {
                lineData.unit_net = 0;
            }
            stepData.push(lineData);
        }

        emit('stepValueChanged', {
            items: stepData,
            currency: currency.value,
        });
    }

    const loadMissingKitComponents = async function () {
        for (const [key, line] of Object.entries(lines)) {
            if (line.part && line.part.kit && !kitComponents.hasOwnProperty(line.id)) {
                kitComponents[line.id] = [];
                try {
                    const res = await useFetch(props.routes.kit_components.replace('%kit%', line.part.kit));
                    let components = await res.json();
                    if (Array.isArray(components)) {
                        components.reverse().forEach((component) => addKitComponentLine(line, component));
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }
    };

    const addKitComponentLine = function (kitLine, componentData) {
        let index = lines.map(line => line.id).indexOf(kitLine.id);
        let lineObj = {
            id: Math.floor(Math.random() * Date.now()).toString(36),
            quantity: componentData.quantity,
            unit_net: componentData.retail_price,
            discount: 0,
            part: componentData,
            parent: kitLine.id,

        };

        addLine(lineObj, index + 1);
        kitComponents[kitLine.id].push(lineObj.id);
    }

    if (lines.length > 0) {
        lines.forEach((line) => {
            if (line.parent) {
                if (kitComponents.hasOwnProperty(line.parent)) {
                    kitComponents[line.parent].push(line.id);
                } else {
                    kitComponents[line.parent] = [line.id];
                }
            }
        })
        updateParentWithValues();
    } else {
        // Add blank line to save user clicking "Add Item" initially
        addLine();
    }

</script>
