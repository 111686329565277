<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <tableFilter label="Status"
                             :options="{ 1: 'Picking Required', 20: 'Completed' }"
                             :selected="1"
                             :clearable="false"
                             @change="(value, label) => filterChanged('status', value)"></tableFilter>
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all customer orders..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="picking-list:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="id">Order</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        width="15%"
                        orderBy="status"
                        class="text-center">Status</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="customer">Customer</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="unallocated_items_count"
                        class="text-center">Items To Pick</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="placed_at"
                        class="text-center">Placed</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="order in data"
                :key="order.id">
                <td class="text-nowrap">
                    <span class="me-1">
                        <CustomerOrderDrawer :source-url="order.quick_view_url"></CustomerOrderDrawer>
                    </span>
                    <span v-html="order.id"></span>
                </td>
                <td class="text-center"
                    v-html="order.status"></td>
                <td v-html="order.customer"></td>
                <td class="text-center">{{ order.unallocated_items_count }}</td>
                <td class="text-center">{{ order.placed_at }}</td>
                <td v-html="order.actions"></td>
            </tr>
        </template>
        <template #smallScreenLayout="{ data }">
            <div v-for="order in data"
                :key="order.id"
                class="list-group-item d-flex flex-column gap-3">
                <div class="d-flex justify-content-between">
                    <h5 class="mb-0" v-html="`Order ${order.id}`"></h5>
                    <small>{{ order.placed_at }}</small>
                </div>
                <div class="d-flex flex-column gap-1">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>Customer</div>
                        <div class="text-end" v-html="order.customer"></div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div>Status</div>
                        <div class="text-end" v-html="order.status"></div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div>Items To Pick</div>
                        <div class="text-end">{{ order.unallocated_items_count }}</div>
                    </div>
                </div>
                <div v-html="order.actions"></div>
            </div>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import tableFilter from '@/components/admin/datatable/tableFilter.vue';
    import { ref, reactive } from "vue";
    import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

    const props = defineProps({
        ajaxUrl: String,
    });

    const orderBy = ref('placed_at');
    const orderAsc = ref(false);
    const limit = ref(10);
    const searchTerm = ref(null);
    const customFilter = reactive({});
    filterChanged('manufacturer', 1);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };


    function filterChanged(key, value) {
        if (customFilter[key] == value) {
            delete customFilter[key];
        } else {
            customFilter[key] = value;
        }
    }
</script>
