<template>
    <label for="customer"
           class="control-label required">Customer</label>
    <formSelect name="customer"
                v-model="customer"
                @update:modelValue="updateParentWithValues"
                :url="routes.customers"
                placeholder="Select customer"
                clearable>
    </formSelect>
    <h5 class="center-line-text my-3">OR</h5>

    <div class="mb-4 form-group">
        <label for="name"
               class="control-label required">Name</label>
        <input class="form-control"
               required="required"
               name="name"
               type="text"
               id="name"
               ref="nameInput"
               @input="updateParentWithValues">
    </div>

    <emailInput :validationUrl="routes.validate_email"
                v-model="email"
                :showValidation="showEmailValidation"
                required></emailInput>

    <div class="mb-4 form-group">
        <label for="telephone"
               class="control-label">Telephone</label>
        <input class="form-control"
               name="telephone"
               type="text"
               id="telephone"
               ref="telephoneInput"
               @input="updateParentWithValues">
    </div>
</template>

<script setup>
    import { ref, watch } from "vue";
    import debounce from 'lodash/debounce';
    import { useFetch } from "@/utilities/useFetch.js";
    import formSelect from '@/components/admin/utilities/formSelect.vue';
    import emailInput from '@/components/admin/utilities/emailInput.vue';

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });

    let customer = ref(props.extras.preselected.customer);
    let nameInput = ref(null);
    let telephoneInput = ref(null);

    const email = ref(null);
    const showEmailValidation = ref(true);
    watch(email, updateParentWithValues);

    function updateParentWithValues() {
        window.dispatchEvent(new CustomEvent('clearNotifications'));
        if (customer.value) {
            showEmailValidation.value = false;
            emit('stepValueChanged', {
                customer: customer.value.value,
                name: customer.value.name,
                email: customer.value.email,
                telephone: customer.value.telephone,
            });

            if (customer.value.value) {
                fetchComment(props.routes.customer_comments.replace('%customer%', customer.value.value));
            }
        } else {
            showEmailValidation.value = true;
            emit('stepValueChanged', {
                customer: null,
                name: nameInput.value.value ? nameInput.value.value : null,
                email: email.value,
                telephone: telephoneInput.value.value ? telephoneInput.value.value : null,
            });
        }
    }

    const fetchComment = debounce(ajaxComment, 350);

    let fetchAbort, fetchAbortSignal;
    async function ajaxComment(url) {
        if (!url) {
            return;
        }

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            const res = await useFetch(url, { signal: fetchAbortSignal });
            let response = await res.json();
            if (response.comments) {
                window.dispatchEvent(new CustomEvent('pushNotification', {
                    detail: {
                        icon: 'warning',
                        text: '<b>Customer Notes: </b>' + response.comments,
                    }
                }));
            }

        } catch (err) {
            console.error(err);
        }
    }
</script>
