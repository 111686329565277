<template>
    <div class="border border-2 rounded border-dashed p-3 user-select-none"
         @drop.prevent="dropHandler"
         @dragover.prevent="">
        <div class="d-flex column-gap-2 flex-wrap flex-md-nowrap">
            <div v-if="filePreviews.length > 0"
                 class="d-grid grid-flow-column gap-3 me-md-3 overflow-x-auto px-1"
                 :class="{
                    'pt-4 pb-3': multiple,
                 }"
                 ref="previewContainer">
                <FileUpload v-for="(preview, index) in filePreviews"
                            ref="fileUploads"
                            :source="source"
                            :key="preview.id"
                            :file="preview.file"
                            :is-dragged="preview.isDragged"
                            :name-prefix="namePrefix"
                            :id="preview.id"
                            :mime-type="preview.mime_type"
                            :temp-upload-form-url="tempUploadFormUrl"
                            :is-first="index === 0"
                            :is-last="index === filePreviews.length - 1"
                            :can-be-moved="multiple"
                            :show-primary-label="showPrimaryLabel"
                            :allowed-format="allowedFormat"
                            :allow-comments="allowComments"
                            :comments="preview.comments"
                            :public-view-url="preview.public_view_url"
                            :public-download-url="preview.public_download_url"
                            @request-move-left="tryMoveLeft(index)"
                            @request-move-right="tryMoveRight(index)"
                            @remove="remove(index)"
                            @invalid-file="remove(index)"
                            @file-validated="fileValidated"
                >
                </FileUpload>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1 align-items-center fs-6 text-muted text-center"
                :class="{
                    'd-flex': filePreviews.length === 0 || multiple,
                    'd-none': filePreviews.length > 0 && !multiple,
                }"
                 @click.prevent="clickUpload"
                 role="button"
                 :style="multiple ? 'min-height: 165px; flex:1 0 200px' : 'min-height: 125px; flex:1 0 200px'">
                <i class="far fa-image fs-1"
                   style="opacity: 0.6;"></i>
                <span v-html="placeholderText ?? (multiple ? 'Drop images here or click to upload.' : 'Drop image here or click to upload.')"></span>
            </div>
        </div>
        <input type="hidden"
               :name="namePrefix"
               :value="serializedFileUploads" />
    </div>
    <input class="d-none"
           :id="namePrefix"
           type="file"
           capture="environment"
           :accept="allowedFormat"
           :multiple="multiple"
           @change="handleClickUpload"
           ref="fakeFileInput">
</template>

<script setup>
    import {ref, watch, nextTick, onMounted} from "vue";
    import FileUpload from "@/components/admin/utilities/fileUpload/fileUpload.vue";

    const props = defineProps({
        tempUploadFormUrl: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        namePrefix: {
            type: String,
            default: "upload",
        },
        existingFiles: {
            type: Array,
            default: () => [],
            validator: (value) => {
                return value.every((file) =>
                     Object.hasOwn(file, 'id')
                        && Object.hasOwn(file, 'file')
                        && Object.hasOwn(file, 'mime_type')
                );
            },
        },
        allowedFormat: {
            type: String,
            default: 'image/png, image/jpeg',
        },
        showPrimaryLabel: {
            type: Boolean,
            default: false,
        },
        allowComments: {
            type: Boolean,
            default: false,
        },
        placeholderText: {
            type: String,
            default: null,
        },
        source: String,
    });

    const fileUploads = ref(null);
    const fakeFileInput = ref(null);
    const previewContainer = ref(null);
    const filePreviews = ref(props.existingFiles);
    const serializedFileUploads = ref(null);

    onMounted(() => {
        serializedFileUploads.value = JSON.stringify(serialize());
    });

    watch(
        () => props.existingFiles,
        (newValue) => (filePreviews.value = newValue)
    );

    watch(
        () => filePreviews.value,
        () => (serializedFileUploads.value = JSON.stringify(serialize())),
        {deep: true}
    );

    function dropHandler(e) {
        if (filePreviews.value.length > 0 && !props.multiple) {
            return;
        }
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s).
            [...e.dataTransfer.items].every((item) => {
                // If dropped items aren't files, reject them.
                if (item.kind === "file") {
                    const success = addFile(item.getAsFile(), true);
                    return success && props.multiple;
                }
                return true;
            });
        } else {
            // Use DataTransfer interface to access the file(s).
            [...e.dataTransfer.files].every((file) => {
                const success = addFile(file, true);
                return success && props.multiple;
            });
        }
    }

    function addFile(file, isDragged = false) {
        // Add type validation.
        filePreviews.value.push({ id: Math.floor(Math.random() * Date.now()).toString(36), file: file, isDragged: isDragged });
        // Scroll to the end to show newly uploaded file.
        nextTick(() => {
            previewContainer.value.scroll({
                left: 99999,
                top: 0,
                behavior: 'smooth',
            });
        });

        return true;
    }

    function fileValidated() {
        serializedFileUploads.value = JSON.stringify(serialize());
    }

    function clickUpload() {
        if (filePreviews.value.length > 0 && !props.multiple) {
            return;
        }
        fakeFileInput.value.click();
    }

    function handleClickUpload(e) {
        if (e.target.files) {
            Array.from(e.target.files).every((file) => {
                const success = addFile(file);
                return success && props.multiple;
            });
        }
    }

    function tryMoveLeft(index) {
        if (index === 0) {
            return;
        }

        let temp = filePreviews.value[index];
        filePreviews.value[index] = filePreviews.value[index - 1];
        filePreviews.value[index - 1] = temp;

        temp = fileUploads.value[index];
        fileUploads.value[index] = fileUploads.value[index - 1];
        fileUploads.value[index - 1] = temp;
    }

    function tryMoveRight(index) {
        if (index === filePreviews.value.length - 1) {
            return;
        }

        let temp = filePreviews.value[index];
        filePreviews.value[index] = filePreviews.value[index + 1];
        filePreviews.value[index + 1] = temp;

        temp = fileUploads.value[index];
        fileUploads.value[index] = fileUploads.value[index + 1];
        fileUploads.value[index + 1] = temp;
    }

    function remove(index) {
        filePreviews.value.splice(index, 1);
        fileUploads.value.splice(index, 1);
    }

    function serialize() {
        let data = {};

        if (!fileUploads.value) {
            return data;
        }

        fileUploads.value.forEach((fileUpload) => {
            data[fileUpload.name] = fileUpload.serialize();
        });

        return data;
    }

    defineExpose({
        serialize,
    });
</script>
