<template>
    <div class="form-group mb-4">
        <label for="customer"
               class="control-label">Customer</label>
        <input class="form-control"
               type="text"
               disabled
               :value="extras.preselected.customer.name" />
    </div>
    <div class="form-group mb-4">
        <label for="customer"
               class="control-label">Email</label>
        <input class="form-control"
               type="text"
               disabled
               :value="extras.preselected.customer.email" />
    </div>
    <div class="form-group mb-4">
        <label for="customer"
               class="control-label">Telephone</label>
        <input class="form-control"
               type="text"
               disabled
               :value="extras.preselected.customer.telephone" />
    </div>
</template>

<script setup>
    import { reactive } from "vue";

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });

    let stepData = reactive({
        id: props.extras.preselected.customer.id,
        name: props.extras.preselected.customer.name,
        email: props.extras.preselected.customer.email,
        telephone: props.extras.preselected.customer.telephone,
        defaultCurrencyCode: props.extras.preselected.customer.default_currency_code,
    });

    const updateParentWithValues = function () {
        emit('stepValueChanged', stepData);
    }
    updateParentWithValues();

</script>
