<template>
    <div class="mb-4 form-group">
        Manufacturer
        <formSelect name="manufacturer"
                    placeholder="Select manufacturer"
                    v-model="selectedManufacturer"
                    :options="manufacturers"
                    clearable>
        </formSelect>
    </div>
    <div class="mb-4 form-group">
        Series
        <formSelect name="series"
                    placeholder="Select series"
                    v-model="selectedSeries"
                    :options="series"
                    :disabled="!seriesSelectActive"
                    clearable>
        </formSelect>
        <p v-if="!seriesSelectActive"
           class="text-muted">Please select manufacturer first.</p>
    </div>
    <div class="mb-4 form-group">
        Models
        <formSelect name="vehicle"
                    placeholder="Select vehicle"
                    v-model="selectedVehicle"
                    :options="vehicles"
                    :disabled="!vehiclesSelectActive"
                    clearable>
        </formSelect>
        <p v-if="!vehiclesSelectActive"
           class="text-muted">Please select series first.</p>
    </div>
</template>

<script setup>
    import { ref, computed, watch } from "vue";
    import formSelect from '@/components/admin/utilities/formSelect.vue';
    import { useFetch } from "@/utilities/useFetch.js";

    const emit = defineEmits(['valueChanged']);

    const props = defineProps({
        routes: Object,
        preselectedManufacturer: {
            type: Object,
            default: null
        },
        preselectedSeries: {
            type: Object,
            default: null
        },
        preselectedVehicle: {
            type: Object,
            default: null
        },
    });

    const manufacturers = ref([]);
    const series = ref([]);
    const vehicles = ref([]);

    let allSeries = [];
    let allVehicles = [];

    const selectedManufacturer = ref(props.preselectedManufacturer);
    const selectedSeries = ref(props.preselectedSeries);
    const selectedVehicle = ref(props.preselectedVehicle);

    const seriesSelectActive = computed(() => selectedManufacturer.value != null);
    const vehiclesSelectActive = computed(() => selectedSeries.value != null);

    watch(() => selectedManufacturer.value, (newValue, oldValue) => manufacturerChange(newValue, oldValue));
    watch(() => selectedSeries.value, (newValue, oldValue) => seriesChange(newValue, oldValue));
    watch(() => selectedVehicle.value, () => emit('valueChanged'));

    loadData();
    async function loadData() {
        try {
            // Manufacturers
            let res = await useFetch(props.routes.manufacturers);
            let response = await res.json();
            manufacturers.value = response.data;
            if (selectedManufacturer.value == null && manufacturers.value.length > 0) {
                selectedManufacturer.value = manufacturers.value[0];
            }

            // Series
            res = await useFetch(props.routes.series);
            response = await res.json();
            allSeries = response.data;
            updateSeriesOptions();

            // Vehicles
            res = await useFetch(props.routes.vehicles);
            response = await res.json();
            allVehicles = response.data;
            updateVehicleOptions();

            manufacturerChange(selectedManufacturer.value, selectedManufacturer.value);
            seriesChange(selectedSeries.value, selectedSeries.value);
        } catch (err) {
            console.error(err);
        }
    }

    function updateSeriesOptions(filterManufacturer = null) {
        if (filterManufacturer == null) {
            series.value = allSeries;
        } else {
            series.value = allSeries.filter((entry) => entry.manufacturer === filterManufacturer);
        }
    }

    function updateVehicleOptions(filterSeries = null) {
        if (filterSeries == null) {
            vehicles.value = allVehicles;
        } else {
            vehicles.value = allVehicles.filter((entry) => entry.series === filterSeries);
        }
    }

    function manufacturerChange(newValue, oldValue) {
        if (newValue === null || newValue.value === null || (oldValue !== null && newValue.value !== oldValue.value)) {
            selectedSeries.value = null;
        }

        if (newValue !== null && newValue.value !== null) {
            updateSeriesOptions(newValue.value);
        }
        emit('valueChanged');
    }
    function seriesChange(newValue, oldValue) {
        if (newValue === null || newValue.value === null || (oldValue !== null && newValue.value !== oldValue.value)) {
            selectedVehicle.value = null;
        }
        if (newValue !== null && newValue.value !== null) {
            updateVehicleOptions(newValue.value);
        }
        emit('valueChanged');
    }

    defineExpose({
        manufacturer: selectedManufacturer,
        series: selectedSeries,
        vehicle: selectedVehicle,
    })
</script>
