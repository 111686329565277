<template>
    <div class="text-muted mt-2">
        <template v-if="canBeEdited">
            <i v-if="isLoading"
               class="fa fa-fw fa-spin fa-spinner fs-sm me-1"></i>
            <i v-else
               class="fa fa-fw fa-pencil-alt fs-sm me-1 text-primary change-sold-by"
               role="button"
               @click="updateSoldBy"></i>
        </template>
        <template v-if="selectedSoldByName">Sold By: {{ selectedSoldByName }}</template>
        <template v-else>Salesman not assigned</template>
    </div>
</template>
<script setup>
    import { ref } from "vue";
    import { useFetch } from "@/utilities/useFetch.js";

    const props = defineProps({
        canBeEdited: {
            type: Boolean,
            default: false,
        },
        itemName: {
            type: String,
            required: true,
        },
        soldByName: String,
        soldById: String,
        exceptSalesmanId: String,
        salesmanUsers: Object,
        soldByUpdateUrl: String,
    });

    let selectedSoldBy = props.soldById;
    const selectedSoldByName = ref(props.soldByName);
    const isLoading = ref(false);

    const updateSoldBy = async function () {
        const { value: newSoldBy } = await Swal.fire({
            target: document.getElementById('page-container'),
            icon: 'question',
            title: 'Update salesman who sold this item?',
            text: 'Select salesman who sold ' + props.itemName,
            showCancelButton: true,
            // confirmButtonText: 'Pick',
            customClass: {
                confirmButton: 'bg-primary',
            },
            reverseButtons: true,
            focusConfirm: false,
            input: "select",
            inputOptions: props.salesmanUsers,
            inputValue: selectedSoldBy,
            didOpen: (el) => {
                if (props.exceptSalesmanId) {
                    el.querySelector(`option[value="${props.exceptSalesmanId}"]`)?.setAttribute('disabled', 'disabled');
                }
            }
        });
        if (newSoldBy && newSoldBy !== selectedSoldBy) {
            ajaxSoldBy(newSoldBy, loading);
        }
    }

    const loading = function (_isLoading) {
        isLoading.value = _isLoading;
    }

    let fetchAbort, fetchAbortSignal;
    const ajaxSoldBy = async function (newSoldBy, loading) {
        loading = loading || function () { };

        loading(true);

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            let urlToFetch = new URL(props.soldByUpdateUrl);

            const res = await useFetch(urlToFetch, {
                method: 'POST',
                body: {
                    user: newSoldBy,
                },
                signal: fetchAbortSignal
            });

            let response = await res.json();
            if (response.success) {
                selectedSoldBy = newSoldBy;
                selectedSoldByName.value = props.salesmanUsers[selectedSoldBy];
            } else if (response.message) {
                Swal.fire({
                    target: document.getElementById('page-container'),
                    icon: 'error',
                    title: 'Error',
                    text: response.message,
                });
            }

        } catch (err) {
            console.error(err);
        } finally {
            loading(false);
        }
    }
</script>
