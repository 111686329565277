<template>
    <div class="block block-rounded">
        <div class="block-header block-header-default d-flex">
            <h3 class="block-title">{{ title }}</h3>
        </div>

        <PickModal
            ref="pickModal"
            :picking-item="pickingItem"
            :locations-route="locationsRoute"
            :locations="locations"
            :mode="wording"
            @picked="picked"
            @hidden="Object.keys(pickingItem).forEach(key => delete pickingItem[key])"
        />

        <div class="block-content d-flex flex-column gap-2">
            <template v-if="items.length > 0">
                <table v-if="isLargeScreen"
                       class="table table-bordered table-striped table-vcenter items-table picking-list">
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th class="text-center">Qty Required</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <listItem v-for="(item, i) in items"
                                  :key="i"
                                  :locations-route="locationsRoute"
                                  :button-text="buttonText"
                                  :item="item"
                                  @requests-picking="pick">
                        </listItem>
                    </tbody>
                </table>
                <div v-else>
                    <div class="d-flex flex-column gap-2 mb-4">
                        <listItem v-for="(item, i) in items"
                                  :key="i"
                                  :locations-route="locationsRoute"
                                  :button-text="buttonText"
                                  :item="item"
                                  :showBorder="i !== items.length - 1"
                                  mobileLayout
                                  @requests-picking="pick">
                        </listItem>
                    </div>
                </div>
            </template>
            <p v-else
               class="text-center">{{ emptyMessage }}</p>
        </div>
    </div>
</template>

<script setup>
    import listItem from '@/components/admin/picking/requiresPicking/listItem.vue';
    import {reactive, ref} from "vue";
    import PickModal from "@/components/admin/Modals/PickModal.vue";

    const isLargeScreen = window.isLargeScreen;
    const emit = defineEmits(['requests-picking']);
    const props = defineProps({
        items: Array,
        locations: {
            type: Array,
            default: () => [],
        },
        wording: {
            type: String,
            default: 'picking',
            validator: (propValue) => {
                return ['picking', 'allocation'].includes(propValue);
            },
        },
        locationsRoute: {
            type: String,
            default: '',
        },
    });

    const pickModal = ref(null);
    const pickingItem = reactive({});

    let title, emptyMessage, buttonText;
    if (props.wording === 'picking') {
        title = 'Requires Picking';
        emptyMessage = 'No more items to pick.';
        buttonText = 'Pick';
    } else {
        title = 'Needs Allocating';
        emptyMessage = 'No more items to allocate.';
        buttonText = 'Allocate';
    }

    function pick(item) {
        Object.assign(pickingItem, item);
    }

    function picked(item, quantity, location) {
        emit('requests-picking', item, quantity, location);
        Object.keys(pickingItem).forEach(key => delete pickingItem[key]);
    }
</script>
