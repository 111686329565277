<script setup>
import {useFetch} from "@/utilities/useFetch.js";
import debounce from "lodash/debounce";
import SalesDashboard from "@/components/admin/dashboard/SalesDashboard.vue";
import SalesManagerDashboard from "@/components/admin/dashboard/SalesManagerDashboard.vue";
import WarehouseDashboard from "@/components/admin/dashboard/WarehouseDashboard.vue";
import WorkshopDashboard from "@/components/admin/dashboard/WorkshopDashboard.vue";
import StockManagerDashboard from "@/components/admin/dashboard/StockManagerDashboard.vue";
import {onMounted, ref} from "vue";

const props = defineProps({
    apiRoute: {
        type: String,
        default: "",
    },
});

const components = {
    SalesDashboard,
    SalesManagerDashboard,
    WarehouseDashboard,
    WorkshopDashboard,
    StockManagerDashboard,
};
const tabs = ref([]);
const activeTab = ref(-1);

onMounted(debounce(async () => {
    let req = await useFetch(props.apiRoute);
    tabs.value = (await req.json())?.tabs ?? [];
    activeTab.value = 0;
}, 500));
</script>

<template>
    <div class="block block-rounded block-content" v-if="tabs.length > 1">
        <ul class="nav nav-pills nav-justified push">
            <li class="nav-item me-1" v-for="(tab, index) in tabs">
                <a href="#" class="nav-link" :class="{
                    'active': index === activeTab
                }" @click="activeTab = index" v-text="tab.name"></a>
            </li>
        </ul>
    </div>
    <component v-if="activeTab > -1" :is="components[tabs[activeTab].component]" :api-route="tabs[activeTab].apiRoute" />
    <div class="text-center" v-else>
        <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
