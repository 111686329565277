<template>
    <div v-if="_addressId"
         class="block block-rounded block-bordered address-block d-flex flex-column">
        <div class="block-content d-flex flex-column flex-nowrap flex-grow-1">
            <address v-html="addressPreview"></address>
            <div class="mb-3 d-flex flex-wrap gap-2 mt-auto">
                <a class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-secondary"
                   ref="editButton"
                   :href="filledEditUrl">
                    <i class="fa fa-fw fa-pencil-alt"></i> Edit
                </a>

                <button class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-outline-secondary"
                   ref="cloneButton"
                   @click="cloneButton">
                    <i class="fa fa-fw fa-copy"></i> Copy into Delivery
                </button>

                <button type="button"
                        class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-danger swal-confirm-delete delete-model-button"
                        :data-delete-href="filledDeleteUrl"
                        :data-delete-text="addressPreview"
                        :data-delete-then="'address-deleted:' + _addressId">
                    <i class="fa fa-fw fa-trash me-2"></i>Delete
                </button>
            </div>
        </div>
    </div>
    <a v-else
       class="block block-rounded block-bordered d-flex justify-content-center align-items-center address-block m-0 fs-1 text-default-lighter"
       role="button"
       ref="addButton"
       :href="filledEditUrl">
        <i class="fa fa-plus pe-2"></i> Add
    </a>
</template>
<script setup>
    import { computed, reactive, ref, nextTick, onMounted, onUpdated } from "vue";
    import { addressFormat } from "@/utilities/addressFormat.js";
    import { Eventbus } from '@/utilities/eventbus';

    const props = defineProps({
        address: Object,
        editUrl: String,
        deleteUrl: String,
        addressId: String,
        cloneUrl: String,
    });

    const editButton = ref(null);
    const addButton = ref(null);
    const _addressId = ref(props.addressId);
    const addressModel = reactive({
        ...{
            reference: null,
            is_default: null,
            line_1: null,
            line_2: null,
            line_3: null,
            line_4: null,
            town: null,
            county: null,
            postcode: null,
            country: null,
        },
        ...props.address
    });

    const filledEditUrl = computed(() => {
        let url = new URL(props.editUrl.replace('%address%', _addressId.value ? _addressId.value : ''));
        url.pathname = url.pathname.replace(/\/$/, '');
        return url.href;
    });
    const filledDeleteUrl = computed(() => {
        let url = new URL(props.deleteUrl.replace('%address%', _addressId.value ? _addressId.value : ''));
        url.pathname = url.pathname.replace(/\/$/, '');
        return url.href;
    });
    const addressPreview = computed(() => addressFormat(addressModel));

    const cloneButton = function () {
        fetch(props.cloneUrl)
            .then(response => {
                if (response.ok) {
                    Eventbus.emit('addresses-list:refresh');
                }
            });
    }

    const initAjaxModal = function () {
        const _initAjaxModal = function (trigger) {
            trigger.ajaxModal({
                shown: function (e) {
                    let target = e.target;
                    target.querySelector('#modal_form').ajaxForm({
                        success: function (response) {
                            _addressId.value = response.id;
                            addressModel.value = Object.assign(addressModel, response.address);

                            attachDeleteEventListener();
                            let bsModal = bootstrap.Modal.getInstance(target);
                            if (bsModal) {
                                bsModal.hide();
                            }
                        }
                    });
                }
            });
        }
        if (_addressId.value) {
            _initAjaxModal(editButton.value);
        } else {
            _initAjaxModal(addButton.value);
        }
    }
    onMounted(() => {
        initAjaxModal();
    });

    onUpdated(() => {
        initAjaxModal();
    });

    const attachDeleteEventListener = function () {
        Eventbus.on('address-deleted:' + _addressId.value, () => {
            _addressId.value = null;
        });
    }
    attachDeleteEventListener();

</script>
