<template>
    <div class="dropdown table-filter-dropdown"
         v-if="Object.keys(options).length !== 0">
        <button type="button"
                class="btn btn-alt-secondary dropdown-toggle rounded-end-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
            {{ buttonLabel }}
        </button>
        <div class="dropdown-menu fs-sm h-auto">
            <a v-for="(option) in mappedOptions"
               class="dropdown-item"
               role="button"
               @click.prevent="btnClick(option)">
                {{ option.label }}
            </a>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        options: Array | Object,
        selected: [String, Number],
        clearable: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            required: true,
        }
    });
    let selected = props.selected;

    const mappedOptions = (Array.isArray(props.options) ? props.options : Object.keys(props.options).map((key) => { return { value: key, label: props.options[key] } }))
        .map((option, key) => {
            if (typeof option === 'object') {
                return {
                    value: option.value ? option.value : (option.id ? option.id : key),
                    label: option.label ? option.label : (option.title ? option.title : (option.name ? option.name : option))
                }
            } else {
                return {
                    value: option,
                    label: option
                }
            }
        });
    const buttonLabel = ref('');
    updateLabel(props.selected ? props.options[props.selected] : null);

    const emit = defineEmits(['change']);
    function btnClick(option) {
        if (selected == option.value) {
            if (!props.clearable) {
                return;
            }
            updateLabel();
        } else {
            selected = option.value;
            updateLabel(option.label);
        }

        emit('change', option.value, option.label);
    }

    function updateLabel(value) {
        buttonLabel.value = props.label + (value ? ': ' + value : '');
    }

</script>
