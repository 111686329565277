<template>
    <form ref="wizardForm" :action="routes.form_action">
        <input type="hidden" name="stage" :value="stage">

        <div class="d-flex justify-content-between wizard-header position-relative">
            <div class="border-top spacer-line"></div>
            <template v-for="(step, i) in steps">
                <div :role="i !== currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'"
                     :class="{
                         'd-flex flex-column align-items-center progress-number-wrapper': true,
                         'active': currentStep == i
                     }"
                     @click="attemptGoToStep(i)">
                    <div class="progress-number-inner-wrapper">
                        <span class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number">
                            <i v-if="isAllPreviousStepsValid(i) && i !== currentStep && (i < currentStep || steps[i].validate())"
                               class="fa fa-check text-flat"></i>
                            <span v-else>{{ i }}</span>
                        </span>
                    </div>
                    <span class="fs-xs text-center">{{ step.label }}</span>
                </div>
            </template>
        </div>

        <div class="mt-3 mb-3">
            <div v-show="currentStep == 1">
                <CustomerStep :routes="routes"
                              :stepsData="stepsData"
                              :extras="extras"
                              @stepValueChanged="(data) => setStepData('customer', data)">
                </CustomerStep>
            </div>
            <div v-show="currentStep == 2">
                <ItemsStep :routes="routes"
                           :stepsData="stepsData"
                           :extras="extras"
                           @stepValueChanged="(data) => setStepData('items', data)">
                </ItemsStep>
            </div>
            <div v-if="currentStep == 3">
                <SummaryStep :routes="routes"
                             :extras="extras"
                             :stepsData="stepsData">
                </SummaryStep>
            </div>
        </div>

        <div class="pb-3">
            <div class="d-flex justify-content-end gap-2 position-relative">
                <AttachmentsButton class="position-absolute" style="left: 0;" :attachments="extras.preselected.attachments" />
                <button v-if="currentStep > 1"
                        type="button"
                        class="btn btn-secondary"
                        @click="previousStep">Back</button>
                <div v-if="currentStep === Object.keys(steps).length"
                     class="dropdown">
                    <button type="button"
                            class="btn btn-primary dropdown-toggle"
                            id="complete_wizard"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            :disabled="!steps[currentStep].validate()"
                            ref="submitButtonRef">Save
                    </button>
                    <div class="dropdown-menu fs-sm"
                         aria-labelledby="complete_wizard">
                        <span class="dropdown-item"
                              role="button"
                              @click="requestComplete(false)">Save Draft</span>
                        <span class="dropdown-item"
                              role="button"
                              @click="requestComplete(true)">Save & Submit</span>
                    </div>
                </div>
                <button v-else
                        type="button"
                        class="btn btn-primary"
                        :disabled="!steps[currentStep].validate()"
                        @click="nextStep">Next</button>
            </div>
            <div class="text-end mt-2">
                <div ref="errorContainerRef"></div>
            </div>
        </div>
    </form>
</template>

<script setup>
    import { reactive, ref, nextTick } from "vue";
    import debounce from 'lodash/debounce';
    import CustomerStep from "@/components/admin/quotes/pricing/customerStep.vue";
    import ItemsStep from "@/components/admin/quotes/pricing/itemsStep.vue";
    import SummaryStep from "@/components/admin/quotes/pricing/summaryStep.vue";
    import AttachmentsButton from "@/components/admin/quotes/components/AttachmentsButton.vue";

    const props = defineProps({
        routes: {
            type: Object,
            required: true,
        },
        extras: {
            type: Object,
            default: () => ({}),
        },
        stage: {
            type: String,
            required: true,
        },
    });

    let steps = {
        1: {
            label: 'Customer',
            validate: function () {
                return stepsData.customer;
            }
        },
        2: {
            label: 'Items',
            validate: function () {
                if (!stepsData.items || !stepsData.items.items || stepsData.items.items.length === 0) {
                    return false;
                }
                for (const item of stepsData.items.items) {
                    if (!item.kit_quote_item && (!item.tax || item.tax.value == null)) {
                        return false;
                    }
                }
                return true;
            }
        },
        3: {
            label: 'Summary',
            validate: function () {
                return true;
            }
        },
    };
    const currentStep = ref(1);
    const stepsData = reactive({});
    const submitButtonRef = ref(null);
    const errorContainerRef = ref(null);
    const wizardForm = ref(null);

    const previousStep = function () {
        currentStep.value--;
    }
    const nextStep = function () {
        if (currentStep.value == 2) {
            if (
                (stepsData.items.items.filter((item) => item.is_delivery).length == 0) ||
                (stepsData.items.items.filter((item) => item.is_delivery)[0].unit_net == 0)
            ) {
                confirmFreeDelivery();
                return;
            }
        }

        currentStep.value++;
    }
    const attemptGoToStep = function (requestedStep) {
        if (requestedStep === currentStep.value) {
            return false;
        }
        if (!isAllPreviousStepsValid(requestedStep)) {
            return false;
        }
        currentStep.value = requestedStep;

    }

    const isAllPreviousStepsValid = function (stepNumber) {
        for (let i = 1; i < stepNumber; i++) {
            if (!steps[i].validate()) {
                return false;
            }
        }
        return true;
    }

    const setStepData = function (step, data) {
        stepsData[step] = data;
    }

    let saveAndSubmitToCustomer = false;
    const requestComplete = function (_saveAndSubmitToCustomer = false) {
        saveAndSubmitToCustomer = _saveAndSubmitToCustomer;
        for (const [key, step] of Object.entries(steps)) {
            if (!step.validate()) {
                return false;
            }
        }

        completeWizard();
    }

    const completeWizard = debounce(ajaxSteps, 350);

    async function ajaxSteps() {
        let data = JSON.parse(JSON.stringify(stepsData));
        data.items.items = data.items.items.filter((item) => !item.kit_quote_item);
        data.submitter = submitButtonRef.value;
        if (saveAndSubmitToCustomer) {
            data.submit_to_customer = 1;
        }

        wizardForm.value.dispatchEvent(new CustomEvent('ajax-submit', {
            detail: data
        }));
    }

    function getLastValidStepNumber() {
        for (let i = 1; i < Object.keys(steps).length; i++) {
            if (!steps[i].validate()) {
                return i;
            }
        }
        return Object.keys(steps).length;
    }

    function confirmFreeDelivery() {
        Swal.fire({
            title: 'Free Delivery',
            text: 'Would you like to offer free delivery on this order?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                currentStep.value++;
            }
        });
    }

    nextTick(function () {
        let lastValidStep = getLastValidStepNumber();
        // Pricing stage has everything autofilled with default values, so it would always go to last "Summary" step,
        // but it's very likely that salesman will want to change a price (it's a quote at the end of the day)
        // so on page load, attempt to load "Items" step.
        currentStep.value = lastValidStep > 2 ? 2 : lastValidStep;
        wizardForm.value.ajaxForm({
            validationTarget: errorContainerRef.value,
        });
    });
</script>
