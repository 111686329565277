<template>
    <div :class="{
        'mt-2 py-2 px-3 border rounded': withManufacturerLabel
    }">
        <div v-if="withManufacturerLabel">
            <span v-if="previewMode">{{ manufacturer.title }}</span>
            <div v-else
                 class="form-check form-switch mb-2 ms-3 fst-italic">
                <input class="form-check-input"
                       type="checkbox"
                       :id="'manufacturer_' + uuid"
                       :middle="middle"
                       :checked="checked"
                       @change="(event) => setMainToggleState(event.currentTarget.checked)">
                <label class="form-check-label"
                       :for="'manufacturer_' + uuid">{{ manufacturer.title }}</label>
            </div>
        </div>
        <div class="d-flex flex-column gap-2 mt-1">
            <series v-for="(series, i) in manufacturer.series"
                    :key="i"
                    :preview-mode="previewMode"
                    :preselected-vehicles="preselectedVehicles"
                    :vehicles="series.vehicles"
                    :series-title="series.title"
                    @threeStateToggleChanged="(hasItemsSelected) => threeStateToggleChanged(i, hasItemsSelected)"
                    ref="seriesRef">
            </series>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const emit = defineEmits(['threeStateToggleChanged']);

    const props = defineProps({
        manufacturer: {
            type: Object,
            required: true,
            validator: (propValue) => {
                return Object.hasOwn(propValue, 'title') && Object.hasOwn(propValue, 'series') && Array.isArray(propValue.series);
            }
        },
        preselectedVehicles: Array,
        previewMode: {
            type: Boolean,
            default: false,
        },
        withManufacturerLabel: {
            type: Boolean,
            default: false,
        },
    });
    const uuid = Math.floor(Math.random() * Date.now()).toString(36);

    const checked = ref(false);
    const middle = ref(false);
    const seriesRef = ref(null);

    let selectedSeries = {};
    const threeStateToggleChanged = function (index, threeStateToggleState) {
        selectedSeries[index] = threeStateToggleState;

        checked.value = Object.values(selectedSeries).filter(x => x > -1).length > 0;
        middle.value = checked.value && Object.values(selectedSeries).filter(x => x < 1).length > 0 ? 1 : null;
        emit('threeStateToggleChanged', checked.value ? (middle.value ? 0 : 1) : -1);
    }

    const setMainToggleState = function (checked) {
        seriesRef.value.forEach(function (series) {
            series.toggleState(checked);
        });
    }

    defineExpose({
        toggleState: setMainToggleState,
    });
</script>
