<template>
    <div class="col-sm-6 col-xxl-3">
        <div class="block block-rounded d-flex flex-column h-100 mb-0">
            <div class="block-content flex-grow-1 d-flex justify-content-between">
                <dl class="mb-0">
                    <dt class="fs-3 fw-bold" v-text="value"></dt>
                    <dd class="fs-sm fw-medium text-muted mb-0" v-text="label"></dd>
                </dl>
                <div>
                    <div class="d-inline-block px-2 py-1 rounded-3 fs-xs fw-semibold text-danger bg-danger-light">
                        <i class="fa fa-caret-down me-1"></i>
                        {{ difference }}
                    </div>
                </div>
            </div>
            <div class="block-content p-1 text-center overflow-hidden">
                <LineChart :data="data" :options="options" />
            </div>
        </div>
    </div>
</template>
<script>
import { Line as LineChart }  from 'vue-chartjs';
import { Chart as ChartJS, registerables, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler, ...registerables);



export default {
    components: {LineChart},
    props: {
        color: {
            type: String,
            default: '#007bff'
        },
        graphData: {
            type: Array,
            default: () => [{
                labels: [],
                data: []
            }]
        },
        label: {
            type: String,
            default: 'Example Label'
        },
        value: {
            type: String,
            default: '0'
        },
        difference: {
            type: String,
            default: '0%'
        }
    },
    data() {
        return {
            data: {
                labels: this.props.graphData.labels,
                datasets: [
                    {
                        borderColor: this.color,
                        pointBackgroundColor: this.color,
                        pointBorderColor: this.color,
                        tension: 0.25,
                        ...this.props.graphData.data
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
    },
    mounted() {
        this.renderChart(this.data, this.options);
    }
}
</script>
