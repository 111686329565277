<template>
    <label for="customer"
           class="control-label required">Customer</label>
    <formSelect name="customer"
                v-model="customer"
                @update:modelValue="updateParentWithValues"
                :url="routes.customers"
                :disabled="extras.isFromQuote"
                placeholder="Select customer">
    </formSelect>
    <div class="text-muted"
         v-if="extras.isFromQuote">
        Can't change customer for orders that are converted from quotes.
    </div>
</template>

<script setup>
    import { ref } from "vue";
    import debounce from 'lodash/debounce';
    import { useFetch } from "@/utilities/useFetch.js";
    import formSelect from '@/components/admin/utilities/formSelect.vue';

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
        isInitialLoadingFinished: Boolean,
    });

    let customer = ref(props.extras.preselected.customer);

    const updateParentWithValues = function () {
        emit('stepValueChanged', {
            customer: customer.value.value,
            name: customer.value.name,
            email: customer.value.email,
            telephone: customer.value.telephone,
            defaultCurrencyCode: customer.value.default_currency_code,
        });

        window.dispatchEvent(new CustomEvent('clearNotifications'));

        if (customer.value.value) {
            fetchComment(props.routes.customer_comments.replace('%customer%', customer.value.value));
        }
    }

    const fetchComment = debounce(ajaxComment, 350);

    let fetchAbort, fetchAbortSignal;
    async function ajaxComment(url) {
        if (!url) {
            return;
        }

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            const res = await useFetch(url, { signal: fetchAbortSignal });
            let response = await res.json();
            if (response.comments) {
                window.dispatchEvent(new CustomEvent('pushNotification', {
                    detail: {
                        icon: 'warning',
                        text: '<b>Customer Notes: </b>' + response.comments,
                    }
                }));
            }

        } catch (err) {
            console.error(err);
        }
    }


</script>
