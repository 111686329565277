<template>
    <div v-if="tabData !== null">
        <div class="row items-push">
            <tile-detailed title="Stock Check Orders" :value="tabData.tiles.stock_check" button-text="View Stock Checks" :button-link="tabData.tiles.stock_check_route" icon="fa fa-file-lines"></tile-detailed>
            <tile-detailed title="In Stock Parts" :value="tabData.tiles.stock_levels.in_stock" button-text="View Parts" :button-link="tabData.tiles.stock_route" icon="fa fa-file"></tile-detailed>
            <tile-detailed title="Low Stock Parts" :value="tabData.tiles.stock_levels.low_stock" button-text="View Parts" :button-link="tabData.tiles.stock_route" icon="fa fa-file"></tile-detailed>
            <tile-detailed title="Out of Stock Parts" :value="tabData.tiles.stock_levels.no_stock" button-text="View Parts" :button-link="tabData.tiles.stock_route" icon="fa fa-file"></tile-detailed>
        </div>
        <div class="block block-rounded">
            <ul class="nav nav-tabs nav-tabs-block" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" aria-selected="true">Stock Check</button>
                </li>
            </ul>
            <div class="block-content block-content-full">
                <customer-order-list :ajax-url="tabData.stock_check.list_route" stripped-version></customer-order-list>
            </div>
        </div>
    </div>
    <div class="block-content text-center" v-else>
        <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
<script>

    import {useFetch} from "../../../utilities/useFetch";
    import debounce from "lodash/debounce";
    import TileDetailed from "../utilities/oneui/tileDetailed.vue";
    import CustomerOrderList from '../customerOrders/list.vue';
    import QuotesTable from "./components/quotesTable.vue";

    export default {
        components: {QuotesTable, TileDetailed, CustomerOrderList},

        data() {
            return {
                tabData: null
            }
        },
        props: {
            'apiRoute': {
                type: String,
                default: 'none'
            }
        },
        mounted() {
            const component = this;
            const getTabData = debounce(async function () {
                let req = await useFetch(component.apiRoute);
                component.tabData = await req.json();

                return component.tabData;
            }, 500);
            getTabData();
        }
    }
</script>
