<template>
    <input type="hidden" :name="name" :value="currentValue" />
    <div :class="{
    'form-check form-switch toggle-wrapper': true,
    [toggleStyle]: true,
    [classes]: true
  }">
        <input class="form-check-input"
               type="checkbox"
               @change.prevent="toggleChange"
               :disabled="disabled"
               :id="id"
               ref="toggle">
    </div>
</template>

<script setup>
import { ref, nextTick, watch } from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: Boolean,
    disabled: Boolean,
    name: String,
    toggleStyle: {
        type: String,
        default: 'traffic-light',
        validator(value) {
            return ['default', 'traffic-light'].includes(value);
        }
    },
    classes: String,
});

const currentValue = ref(props.modelValue ?? false);
const toggle = ref(null);
const id = Math.random().toString(36);

nextTick(() => setToggleStateByValue(currentValue.value));
watch(currentValue, newValue => emit('update:modelValue', newValue));
watch(() => props.modelValue, newValue => setToggleStateByValue(newValue));

const toggleChange = function () {
    currentValue.value = !currentValue.value;
    setToggleStateByValue(currentValue.value);
}

const setToggleStateByValue = function (value) {
    toggle.value.checked = value;
}
</script>
