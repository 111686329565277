<template>
    <tr>
        <td>
            <partFormSelect :name="`components[${index}][part]`"
                            v-model="kitComponent.part">
            </partFormSelect>
        </td>
        <td class="text-center quantity">
            <input class="form-control text-center m-auto qty-input"
                   type="text"
                   inputmode="numeric"
                   pattern="[0-9]*"
                   placeholder="1"
                   :name="`components[${index}][quantity]`"
                   v-model="kitComponent.quantity" />
        </td>
        <td v-html="`£${unitNet}`">
        </td>
        <td>
            <i class="fas fa-times text-danger"
               role="button"
               @click="emit('removeItem', index)"></i>
        </td>
    </tr>
</template>

<script setup>
    import { reactive, ref, watch, computed } from "vue";
    import partFormSelect from '@/components/admin/utilities/partFormSelect.vue';
    import { priceFormat } from "@/utilities/priceFormat.js";

    const emit = defineEmits(['removeItem']);

    const props = defineProps({
        kitComponent: Object,
        partsUrl: String,
        index: String,
    });

    const unitNet = computed(() => props.kitComponent.part ? priceFormat(props.kitComponent.part.retail_price.toFixed(2)) : "0.00");
    const partPlaceholderImage = window.placeholderImage;
</script>
