<script setup>
import {onMounted, ref} from "vue";

const emit = defineEmits(['shown', 'hidden']);
const props = defineProps({
    size: {
        type: String,
        default: 'md',
        validate: (value) => ['md', 'sm', 'lg', 'xl'].includes(value),
    },
    centered: {
        type: Boolean,
        default: false,
    },
});

const modalElement = ref(null);
const bsModal = ref(null);
const sizeClass = ref(props.size === 'md' ? '' : `modal-${props.size}`);

onMounted(() => {
    bsModal.value = new bootstrap.Modal(modalElement.value);
    modalElement.value.addEventListener('shown.bs.modal', () => emit('shown'));
    modalElement.value.addEventListener('hidden.bs.modal', () => emit('hidden'));
});

defineExpose({
    modalElement,
    bsModal,
});
</script>

<template>
    <Teleport to="body">
        <div class="modal fade" tabindex="-1" aria-hidden="true" ref="modalElement">
            <div role="document" :class="{'modal-dialog modal-dialog-popout': true, [sizeClass]: true, 'modal-dialog-centered': centered}">
                <div class="modal-content">
                    <div class="block block-rounded block-transparent mb-0">
                        <div class="block-header block-header-default">
                            <h3 class="block-title"><slot name="title" /></h3>
                            <div class="block-options">
                                <button type="button" class="btn-block-option" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-fw fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <div class="block-content fs-sm">
                            <slot />
                        </div>
                        <div class="block-content block-content-full text-end bg-body">
                            <slot name="footer" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>
