<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search parts..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               refreshOn="location-stock:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="name">Part</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="sku">SKU</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="stock"
                        class="text-center">Stock</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="is_active"
                        class="text-center">Active</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="part in data"
                :key="part.id">
                <td>
                    <partDrawer v-if="part.quick_view_url" :sourceUrl="part.quick_view_url"></partDrawer>
                    {{ part.name }}
                </td>
                <td>{{ part.sku }}</td>
                <td class="text-center"
                    v-html="part.stock"></td>
                <td class="text-center">
                    <template v-if="part.is_active">
                        <i class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i class="fa fa-times"></i>
                    </template>
                </td>
                <td v-html="part.actions"></td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import partDrawer from "@/components/admin/parts/partDrawer.vue";
    import { ref } from "vue";

    const props = defineProps({
        ajaxUrl: String,
    });

    const orderBy = ref('name');
    const orderAsc = ref(true);
    const limit = ref(10);
    const searchTerm = ref(null);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };
</script>
