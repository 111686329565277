<template>
    <div class="d-flex gap-1 align-items-center">
        <formSelect :name="name"
                    v-model="model"
                    @update:modelValue="(e) => emit('update:modelValue', e)"
                    :url="partsUrl"
                    :min-dropdown-width="400"
                    :taggable="!onlyExisting"
                    withImage
                    :placeholder="onlyExisting ? 'Type to search for a part' : 'Select part or type custom part name'">
            <template #no-options>Type to search parts..</template>

            <template #option="slotData">
                <img class="img-thumbnail object-fit-cover"
                     :src="slotData.option.main_image ? slotData.option.main_image : partPlaceholderImage" />
                {{ slotData.option.label }}
            </template>

            <template #selected-option="slotData">
                <img class="img-thumbnail object-fit-cover"
                     :src="slotData.option.main_image ? slotData.option.main_image : partPlaceholderImage" />
                {{ slotData.option.label }}
            </template>
        </formSelect>
        <partDrawer v-if="quickViewUrl" :sourceUrl="quickViewUrl"></partDrawer>
    </div>
</template>


<script setup>
    import { ref, watch } from "vue";
    import formSelect from '@/components/admin/utilities/formSelect.vue';
    import partDrawer from '@/components/admin/parts/partDrawer.vue';

    const emit = defineEmits(['update:modelValue']);
    const props = defineProps({
        name: String,
        modelValue: Object | String,
        partsUrl: {
            type: String,
            default: window.partSelectUrl,
        },
        onlyExisting: {
            type: Boolean,
            default: false,
        },
    });

    const quickViewUrl = ref(props.modelValue && typeof props.modelValue === 'object' && props.modelValue.quick_view_url ? props.modelValue.quick_view_url : null);
    const model = ref(props.modelValue);
    const partPlaceholderImage = window.placeholderImage;

    watch(() => props.modelValue, (newValue) => {
        model.value = newValue;
        quickViewUrl.value = newValue && typeof newValue === 'object' && newValue.quick_view_url ? newValue.quick_view_url : null;
    });
</script>
