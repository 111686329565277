<template>
    <div v-if="manufacturingMap.length">
        <span v-if="previewMode || manufacturingMap.length > 1">Models</span>
        <div v-else
             class="form-check form-switch mb-2 ms-3">
            <input class="form-check-input"
                   type="checkbox"
                   :id="'manufacturing_map_' + uuid"
                   :middle="middle"
                   :checked="checked"
                   @change="(event) => toggleAllManufacturers(event.currentTarget.checked)">
            <label class="form-check-label"
                   :for="'manufacturing_map_' + uuid">Models</label>
        </div>
        <manufacturer v-for="(manufacturer, index) in manufacturingMap"
                      :key="index"
                      :manufacturer="manufacturer"
                      :preselected-vehicles="preselectedVehicles"
                      :preview-mode="previewMode"
                      :with-manufacturer-label="forceShowManufacturerLabel || manufacturingMap.length > 1"
                      @threeStateToggleChanged="(threeStateToggleState) => threeStateToggleChanged(i, threeStateToggleState)"
                      ref="manufacturerRef">
        </manufacturer>
    </div>
    <div v-else>
        No model assigned.
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        manufacturingMap: {
            type: Array,
            required: true
        },
        preselectedVehicles: Array,
        previewMode: {
            type: Boolean,
            default: false,
        },
        forceShowManufacturerLabel: {
            type: Boolean,
            default: false,
        }
    });

    const uuid = Math.floor(Math.random() * Date.now()).toString(36);

    const checked = ref(false);
    const middle = ref(false);
    const manufacturerRef = ref(null);

    let manufacturerStates = {};
    const threeStateToggleChanged = function (index, threeStateToggleState) {
        manufacturerStates[index] = threeStateToggleState;
        checked.value = Object.values(manufacturerStates).filter(x => x > -1).length > 0;
        middle.value = checked.value && Object.values(manufacturerStates).filter(x => x < 1).length > 0 ? 1 : null;
    }

    const toggleAllManufacturers = function (checked) {
        manufacturerRef.value.forEach((item) => {
            item.toggleState(checked);
        });
    }

</script>
