<template>
    <input type="text"
           class="form-control mb-2"
           placeholder="Search.."
           v-model="searchValue">
    <dl class="row mb-0">
        <dt class="col-sm-3">Manufacturing</dt>
        <dd class="col-sm-9">
            <template v-if="manufacturingMapLookup.length > 0">
                <div v-for="(manufacturer, index) in manufacturingMapLookup"
                     :key="index">
                    <div class="row"
                         v-if="manufacturer.series.length > 0">
                        <div class="col-sm-6 col-md-4"
                             v-for="(series, index) in manufacturer.series"
                             :key="index">
                            <div class="text-muted"
                                 v-if="forceShowManufacturerLabel || manufacturingMapLookup.length > 1">
                                {{ manufacturer.title }} - {{ series.title }}
                            </div>
                            <div class="text-muted"
                                 v-else>
                                {{ series.title }}
                            </div>
                            <ul class="ps-3"
                                v-if="series.vehicles.length > 0">
                                <li v-for="(vehicle, index) in series.vehicles"
                                    :key="index">
                                    {{ vehicle.title }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                -
            </template>
        </dd>
    </dl>
</template>

<script setup>
    import { computed, ref, watch } from 'vue';

    const props = defineProps({
        manufacturingMap: {
            type: Array,
            validator: (value) => {
                return value.every((manufacturer) => {
                    if (! typeof manufacturer === 'object') {
                        return false;
                    }
                    if (!manufacturer.hasOwnProperty('series') || !manufacturer.hasOwnProperty('title')) {
                        return false;
                    }
                    if (!Array.isArray(manufacturer.series) || !manufacturer.series.every((series) => {
                        return series.hasOwnProperty('title')
                            && series.hasOwnProperty('vehicles')
                            && Array.isArray(series.vehicles)
                            && series.vehicles.every((vehicle) => {
                                return vehicle.hasOwnProperty('title');
                            });
                    })) {
                        return false;
                    }
                    return true;
                });
            },
        },
        forceShowManufacturerLabel: Boolean,
    });

    const searchValue = ref('');

    // Filter the manufacturing map based on the search value
    const manufacturingMapLookup = computed(() => {
        if (props.manufacturingMap) {
            if (searchValue.value.length > 0) {
                return props.manufacturingMap.reduce((acc, manufacturer) => {
                    const series = manufacturer.series.reduce((acc, series) => {
                        const vehicles = series.vehicles.filter((vehicle) => {
                            return vehicle.title.toLowerCase().includes(searchValue.value.toLowerCase());
                        });
                        if (vehicles.length > 0) {
                            acc.push({
                                title: series.title,
                                vehicles: vehicles
                            });
                        }
                        return acc;
                    }, []);
                    if (series.length > 0) {
                        acc.push({
                            title: manufacturer.title,
                            series: series
                        });
                    }
                    return acc;
                }, []);
            }

            return props.manufacturingMap;
        }
        return [];
    });
</script>
