<template>
    <input type="hidden"
           :name="name"
           :value="currentValue" />
    <div :class="{
        'form-check form-switch toggle-wrapper': true,
        [toggleStyle]: true,
    }">
        <input class="form-check-input"
               type="checkbox"
               @change.prevent="toggleChange()"
               :disabled="disabled"
               :id="id"
               ref="toggle">
        <template v-if="showLabel">
            <label v-for="(option) in options" :for="id"
                  :class="{
                      'toggle-label': true,
                      'active': currentValue === option.value
                  }">
                {{ option.label }}
            </label>
        </template>
    </div>
</template>

<script setup>
    import { ref, nextTick, watch } from "vue";

    const emit = defineEmits(['update:modelValue']);

    const props = defineProps({
        modelValue: [String, Number],
        disabled: Boolean,
        options: {
            type: Array,
            required: true,
            validator(value) {
                if (!Array.isArray(value)) {
                    return false;
                }

                for (const el of value) {
                    if (typeof el !== 'object' || el.value == null) {
                        return false;
                    }
                }

                return true;
            }
        },
        name: String,
        showLabel: {
            type: Boolean,
            default: true,
        },
        toggleStyle: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'traffic-light'].includes(value)
            }
        }
    });

    const currentValue = ref(props.modelValue == null ? props.options[1].value : props.modelValue);
    const toggle = ref(null);
    const label = ref(null);
    const id = Math.random().toString(36);

    nextTick(() => setToggleStateByValue(indexInList(currentValue.value)));
    watch(currentValue, (newValue) => emit('update:modelValue', newValue));
    watch(() => props.modelValue, (newValue) => toggleChange(newValue));

    const indexInList = function (value) {
        for (const [key, option] of Object.entries(props.options)) {
            if (option.value === value) {
                return parseInt(key);
            }
        }
    }

    const toggleChange = function (newValue = null) {
        let nextIndex;
        if (newValue === null) {
            nextIndex = (indexInList(currentValue.value) + 1) % 3;
        } else {
            nextIndex = indexInList(newValue) % 3;
        }
        setToggleStateByValue(nextIndex);
        currentValue.value = newValue === null ? props.options[nextIndex].value : newValue;
    }

    const setToggleStateByValue = function (value) {
        switch (value) {
            case 0:
                setNegative();
                break;
            case 1:
                setNeutral();
                break;
            case 2:
                setPositive();
                break;
        }
    }

    const setNegative = function () {
        toggle.value.checked = false;
        toggle.value.removeAttribute('middle');
        label.value = props.options[0].label;
    }

    const setNeutral = function () {
        toggle.value.checked = true;
        toggle.value.setAttribute('middle', "");
        label.value = props.options[1].label;
    }

    const setPositive = function () {
        toggle.value.checked = true;
        toggle.value.removeAttribute('middle');
        label.value = props.options[2].label;
    }
</script>

<style lang="scss" scoped>
.toggle-wrapper {
    clip-path: inset(0 0 0 -5px);

    .toggle-label {
        position: absolute;
        transform: translateY(-100%) scaleY(0);
        transition-property: transform;
        transition-duration: 0.25s;
        transition-timing-function: cubic-bezier(0.6, 0.2, 0.4, 1.5);

        &.active {
            transform: translateY(0) scaleY(1);
        }
    }
}
</style>
