<template>
    <template v-if="!isDropShippingOrder">
        <label for="customer_orders"
            class="control-label">Customer orders</label>
        <formSelect name="customer_orders"
                    v-model="orders"
                    @update:modelValue="updateParentWithValues"
                    :url="ordersUrl"
                    placeholder="Select customer orders"
                    helper-text="Select customer orders that would be fulfilled by this order. Search for <kbd>#</kbd> to look for best matches."
                    clearable
                    multiple>
        </formSelect>
    </template>
    <div v-if="orders.length > 0"
         class="mt-4">
        <div v-for="(order, index) in orders"
             :key="order.value">
            <div class="d-flex mb-2">
                <div style="flex: 0 0 40%;">
                    <b>Order:</b> #{{ order.value }}
                    <span v-if="order.invoice_number">({{ order.invoice_number }})</span>
                </div>
                <div>
                    <b>Customer:</b> {{ order.customer_name }}
                </div>
            </div>

            <div class="table-responsive-xl">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th width="70%">Part Name</th>
                            <th width="15%">SKU</th>
                            <th class="text-center"
                                width="15%">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in order.items"
                            :class="{
                    'text-muted': !item.part_id || !partsIds.includes(item.part_id)
                }">
                            <td>{{ item.name }}</td>
                            <td>{{ item.sku }}</td>
                            <td class="text-center">{{ item.quantity }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr v-if="index + 1 !== orders.length"
                class="opacity-75" />
        </div>
    </div>
</template>

<script setup>
    import { ref, watch } from "vue";
    import formSelect from '@/components/admin/utilities/formSelect.vue';

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });

    const orders = ref(props.extras.preselected.orders ? props.extras.preselected.orders : []);
    const ordersUrl = ref(props.routes.customer_orders);
    const partsIds = ref([]);
    const isDropShippingOrder = ref(false);

    watch(props.stepsData, () => onStepsDataChange());

    watch(
        () => isDropShippingOrder.value,
        () => updateParentWithValues()
    );

    if (orders.value.length > 0) {
        updateParentWithValues();
    }

    function onStepsDataChange() {
        partsIds.value = [];
        if (props.stepsData.items) {
            if (props.stepsData.items.drop_shipping_order) {
                isDropShippingOrder.value = true;
                orders.value = [props.stepsData.items.drop_shipping_order];
                orders.value[0].items = props.stepsData.items.items;
                orders.value[0].items.forEach(function (item) {
                    partsIds.value.push(item.part_id);
                });
            } else {
                let url = new URL(props.routes.customer_orders);
                let parts = props.stepsData.items.items.map(function (item) {
                    partsIds.value.push(item.part?.value);
                    return item.part?.value;
                }).filter(x => x);

                if (parts.length > 0) {
                    url.searchParams.set('parts', JSON.stringify(parts));
                }
                ordersUrl.value = url.toString();
            }
        }

    }

    function updateParentWithValues() {
        emit('stepValueChanged', {
            orders: orders.value,
        });
    }
</script>

<style lang="scss" scoped>
table tbody td {
    color: inherit !important;
}
</style>
