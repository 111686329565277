<template>
    <label for="supplier"
           class="control-label required">Supplier</label>
    <formSelect name="supplier"
                v-model="supplier"
                @update:modelValue="updateParentWithValues"
                :url="routes.suppliers"
                placeholder="Select supplier"
                clearable>
    </formSelect>
    <h5 class="center-line-text my-3">OR</h5>

    <div class="mb-4 form-group">
        <label for="name"
               class="control-label required">Name</label>
        <input class="form-control"
               required="required"
               name="name"
               type="text"
               id="name"
               ref="nameInput"
               @input="updateParentWithValues">
    </div>

    <emailInput :validationUrl="routes.validate_email"
                v-model="email"
                :showValidation="showEmailValidation"></emailInput>

    <div class="mb-4 form-group">
        <label for="telephone"
               class="control-label">Telephone</label>
        <input class="form-control"
               name="telephone"
               type="text"
               id="telephone"
               ref="telephoneInput"
               @input="updateParentWithValues">
    </div>
</template>

<script setup>
    import { ref, watch } from "vue";
    import formSelect from '@/components/admin/utilities/formSelect.vue';
    import emailInput from '@/components/admin/utilities/emailInput.vue';

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });

    let supplier = ref(props.extras.preselected.supplier);
    let nameInput = ref(null);
    let telephoneInput = ref(null);

    const email = ref(null);
    const showEmailValidation = ref(true);
    watch(email, updateParentWithValues);

    function updateParentWithValues() {
        if (supplier.value) {
            showEmailValidation.value = false;
            emit('stepValueChanged', {
                supplier: supplier.value.value,
                name: supplier.value.name,
                email: supplier.value.email,
                telephone: supplier.value.telephone,
            });
        } else {
            showEmailValidation.value = true;
            emit('stepValueChanged', {
                supplier: null,
                name: nameInput.value.value ? nameInput.value.value : null,
                email: email.value,
                telephone: telephoneInput.value.value ? telephoneInput.value.value : null,
            });
        }
    }
</script>
