<script setup>
import {AisHighlight} from "vue-instantsearch/vue3/es";
import {computed, getCurrentInstance, watch} from "vue";

const instance = getCurrentInstance();

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    headerAttribute: {
        type: String,
        default: '',
    },
    headerIcon: {
        type: String,
        default: '',
    },
    item: {
        type: Object,
        required: true,
    },
});

const hasSlot = computed(() => !!instance.slots.default);

watch(
    () => props.headerAttribute,
    (newVal) => {
        if (!hasSlot.value && !newVal) {
            console.error('The "headerAttribute" prop is required when the slot is not used.');
        }
    },
    { immediate: true }
);
</script>

<template>
    <div class="d-flex flex-column w-100">
        <span class="mb-1 fs-6">
            <slot :item="item">
                <i v-if="headerIcon" class="fa-solid fa-fw" :class="`fa-${headerIcon}`"></i>
                <AisHighlight :attribute="headerAttribute" :hit="item" />
            </slot>
        </span>
        <div class="container p-0 text-muted small">
            <div class="row row-cols-1 row-cols-lg-2">
                <template v-for="datum in data" :key="datum.attribute">
                    <div v-if="item[datum.attribute]" class="col">
                        <i class="fa-solid fa-fw me-1" :class="`fa-${datum.icon}`"></i>
                        <AisHighlight :attribute="datum.attribute" :hit="item" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
