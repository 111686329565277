<template>
    <div class="mx-0 mx-md-3 mx-lg-6">
        <div class="d-flex justify-content-between mb-3">
            <div v-if="stepsData.supplier"
                 class="d-flex gap-1">
                <b>Supplier:</b>
                <div>
                    {{ stepsData.supplier.name }}
                    <a v-if="stepsData.supplier.email"
                       class="fs-sm fw-medium text-muted d-block"
                       :href="`mailto:${stepsData.supplier.email}`">
                        <i class="far fa-envelope"></i> {{ stepsData.supplier.email }}
                    </a>
                    <a v-if="stepsData.supplier.telephone"
                       class="fs-sm fw-medium text-muted d-block"
                       :href="`tel:${stepsData.supplier.telephone}`">
                        <i class="fa fa-phone"></i> {{ stepsData.supplier.telephone }}
                    </a>
                </div>
            </div>
            <div v-if="totalNet != '' || supplierOrderNumber != ''"
                 style="flex-basis: 40%">
                <div class="d-flex gap-1">
                    <b>Supplier Order Number:</b>
                    <div>{{ supplierOrderNumber ? supplierOrderNumber : '-' }}</div>
                </div>
                <div class="d-flex gap-1">
                    <b>Order Total Net:</b>
                    <div>£{{ formattedTotalNet }}</div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="table-responsive-xl">
                <table class="table"
                       style="width: 100%">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th class="text-center">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items">
                            <td>
                                <div class="d-flex gap-3">
                                    <img class="img-thumbnail object-fit-cover thumbnail-sm"
                                         :src="item.image ? item.image : partPlaceholderImage" />
                                    <div class="d-flex flex-column gap-1">
                                        {{ item.name }}
                                        <span v-if="item.sku">
                                            SKU: {{ item.sku }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{ item.quantity }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p v-if="comments"
           class="text-prewrap">{{ comments }}</p>
    </div>
</template>

<script setup>
    import { ref, watch, computed } from "vue";
    import { priceFormat } from "@/utilities/priceFormat.js";

    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });
    const partPlaceholderImage = window.placeholderImage;

    const items = ref([]);
    const comments = ref('');
    const supplierOrderNumber = ref('');
    const totalNet = ref('');
    const formattedTotalNet = computed(() => !isNaN(parseFloat(totalNet.value)) ? priceFormat(totalNet.value.toFixed(2)) : '0.00');

    watch(props.stepsData, () => onStepsDataChange());

    onStepsDataChange();
    function onStepsDataChange() {
        if (props.stepsData.items) {
            let mappedItems = [];
            props.stepsData.items.items.forEach(function (item) {
                item = JSON.parse(JSON.stringify(item));
                if (typeof item.part === 'string') {
                    item.name = item.part;
                    item.image = window.placeholderImage;
                    item.sku = null;
                } else {
                    let name = item.part.label.split('(');
                    item.name = name[0].trim();
                    item.sku = 1 in name ? name[1].replace(')', '') : null;
                    item.image = item.part.main_image ? item.part.main_image : window.placeholderImage;
                }
                mappedItems.push(item);
            });

            items.value = mappedItems;
        }
        if (props.stepsData.extra) {
            comments.value = props.stepsData.extra.comments;
            supplierOrderNumber.value = props.stepsData.extra.supplier_order_number;
            totalNet.value = props.stepsData.extra.total_net;
        }
    }
</script>

<style lang="scss" scoped>
.totals {
    display: grid;
    justify-items: end;
    grid-template-columns: auto 175px;
    margin-left: 60%;
    gap: 1rem;

    .divider {
        grid-column: 1/3;
        height: 1px;
        width: 100%;
    }

    .total-value {
        padding-right: 0.75rem;
    }
}

table tr:last-of-type td {
    border-width: 0;
}
</style>
