<template>
    <tr>
        <template v-if="item.parent">
            <td colspan="999">
                <div class="d-flex gap-3 ps-4">
                    <img class="img-thumbnail object-fit-cover thumbnail-xs"
                         :src="item.part.main_image ? item.part.main_image : partPlaceholderImage" />
                    <div class="d-flex flex-column gap-1">
                        {{ item.part.label }}
                        <span>Qty Per Kit: {{ item.quantity }}</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-else>
            <td style="max-width: 180px;">
                <input type="hidden"
                       :name="`items[${index}][id]`"
                       :value="item.id" />
                <span v-if="item.is_delivery">Delivery Charge</span>
                <partFormSelect v-else
                                :name="`items[${index}][part]`"
                                v-model="item.part">
                </partFormSelect>
                <small v-if="stockMessage"
                       class="text-danger">
                    {{ stockMessage }}
                </small>
                <slot name="item-description"></slot>
                <SkuField v-if="typeof item.part !== 'undefined' && !isExistingPart && !item.is_delivery"
                          v-model="item.newPartSKU"
                          :sku-check-url="skuCheckUrl"
                          :item-skus="itemSkus"
                />
            </td>
            <td class="text-center quantity">
                <span v-if="item.is_delivery">1</span>
                <input v-else
                       class="form-control text-center m-auto qty-input"
                       type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       placeholder="1"
                       :name="`items[${index}][quantity]`"
                       v-model="item.quantity" />
            </td>
            <td style="min-width: 140px;">
                <span v-if="!unitPriceEditable && (typeof item.part === 'undefined' || isExistingPart)"
                      v-html="currency.symbol + priceFormat(unitNet.toFixed(2))"></span>
                <div v-else
                     class="input-group">
                    <span class="input-group-text">
                        <i v-if="['EUR', 'USD', 'GBP'].includes(currency.code)"
                           class="fas"
                           :class="{ 'fa-euro-sign': currency.code === 'EUR', 'fa-dollar-sign': currency.code === 'USD', 'fa-pound-sign': currency.code === 'GBP' }">
                        </i>
                        <template v-else>
                            {{ currency.code }}
                        </template>
                    </span>
                    <input class="form-control"
                           type="text"
                           :name="`items[${index}][unit_net]`"
                           inputmode="numeric"
                           pattern="\d+(\.\d{1,2})?"
                           title="Price in pounds, e.g. 95.50"
                           placeholder="0"
                           v-model="unitNet">
                </div>
            </td>
            <td style="min-width: 140px;">
                <div class="input-group">
                    <input class="form-control"
                           type="text"
                           :name="`items[${index}][discount]`"
                           inputmode="numeric"
                           pattern="\d+"
                           title="Percentage discount, e.g. 5"
                           placeholder="0"
                           :disabled="item.is_delivery"
                           v-model="discount">
                    <span class="input-group-text">
                        <i class="fa fa-percent"></i>
                    </span>
                </div>
            </td>
            <td style="max-width: 180px;">
                <formSelect :name="`items[${index}][tax]`"
                            v-model="item.tax"
                            :value="taxRates[0]"
                            :options="taxRates"
                            placeholder="Select tax rate">
                </formSelect>
            </td>
            <td class="text-end"
                style="min-width: 100px">{{ currency.symbol }}{{ lineNet }}</td>
            <td v-if="allowDelete">
                <i class="fas fa-times text-danger"
                   role="button"
                   @click="emit('removeItem', index)"></i>
            </td>
        </template>
    </tr>
</template>

<script setup>
    import { watch, computed, ref } from "vue";
    import formSelect from '@/components/admin/utilities/formSelect.vue';
    import partFormSelect from '@/components/admin/utilities/partFormSelect.vue';
    import { priceFormat } from "@/utilities/priceFormat.js";
    import { useFetch } from "@/utilities/useFetch.js";
    import SkuField from "@/components/admin/utilities/SkuField.vue";

    const emit = defineEmits(['removeItem', 'update:unitNet', 'update:discount', 'update:stockMessage']);
    const props = defineProps({
        item: Object,
        index: [String, Number],
        partsUrl: String,
        taxRates: Object,
        allowDelete: {
            type: Boolean,
            default: true,
        },
        currency: {
            type: Object,
            default: () => ({ code: 'GBP', rate: 1, symbol: '£' }),
            validator: (propValue) => {
                return propValue.hasOwnProperty('code') && propValue.hasOwnProperty('rate') && propValue.hasOwnProperty('symbol');
            }
        },
        unitPriceEditable: Boolean,
        itemSkus: {
            type: Array,
            default: () => [],
        },
        skuCheckUrl: {
            type: String,
            required: true,
        },
    });

    const availableStock = ref(null);
    const partPlaceholderImage = window.placeholderImage;
    const retailPrice = ref(typeof props.item.part === 'object' ? parseFloat(props.item.part.retail_price) : 0);

    watch(
        () => props.item.part,
        (newValue) => {
            availableStock.value = null;
            if (newValue.stock_breakdown_url) {
                fetchStockBreakdown(newValue.stock_breakdown_url);
            }
            retailPrice.value = newValue.retail_price ? parseFloat(newValue.retail_price) : 0;
            emit('update:unitNet', retailPrice.value);
        }
    );


    const backOrderStock = computed(() => {
        if (props.item.is_delivery || availableStock.value === null || availableStock.value >= props.item.quantity) {
            return 0;
        }
        if (availableStock.value < 0) {
            return props.item.quantity;
        }
        return props.item.quantity - availableStock.value;
    });
    const stockMessage = computed(() => {
        let message = null;
        switch (backOrderStock.value) {
            case 0:
                break;
            case 1:
                message = `Current stock: ${availableStock.value}. An additional ${backOrderStock.value} unit will need to be ordered.`;
                break;
            default:
                message = `Current stock: ${availableStock.value}. An additional ${backOrderStock.value} units will need to be ordered.`;
        }
        emit('update:stockMessage', message);
        return message;
    });

    const isExistingPart = computed(() => typeof props.item.part === 'object');

    const unitNet = computed({
        get: () => {
            return isExistingPart.value && !props.unitPriceEditable ? retailPrice.value * props.currency.rate : props.item.unit_net;
        },
        set: (newValue) => {
            newValue = newValue ? parseFloat(newValue) : 0;
            if (isNaN(newValue) || newValue < 0) {
                unitNet.value = 0;
                return;
            }
            emit('update:unitNet', newValue);
        }
    })

    if (props.item.is_delivery) {
        unitNet.value *= props.currency.rate;
    }

    const discount = computed({
        get: () => props.item.discount,
        set: (newValue) => {
            newValue = newValue ? parseFloat(newValue) : 0;
            if (isNaN(newValue) || newValue < 0 || newValue > 100) {
                discount.value = 0;
                return;
            }
            if (newValue != Math.floor(newValue)) {
                discount.value = Math.floor(newValue);
                return;
            }
            emit('update:discount', newValue);
        }
    })

    const lineNet = computed(() => priceFormat((Math.round((unitNet.value * (1 - (discount.value ? discount.value * 0.01 : 0))) * props.item.quantity * 100) / 100).toFixed(2)));

    const fetchStockBreakdown = async (url) => {
        const response = await useFetch(url);
        const data = await response.json();
        availableStock.value = parseInt(data.free_stock) + parseInt(data.unallocated_stock);
    }
</script>
