<template>
    <div :class="notificationClass"
         role="alert"
         style="white-space: pre;"
         v-html="notification.text">
    </div>
</template>

<script setup>
    import { computed } from "vue";
    const props = defineProps({
        notification: Object,
        validator: function (value) {
            return value.hasOwnProperty('icon') && value.hasOwnProperty('text');
        }
    });

    const notificationClass = computed(() => 'alert alert-' + props.notification.icon);
</script>
