<template>
    <div class="mx-0 mx-md-3 mx-lg-6">
        <div class="d-flex mb-3 gap-1">
            <b>Customer:</b>
            <div v-html="customer"></div>
        </div>
        <div v-if="manufacturing"
             class="d-flex mb-3 gap-1">
            <b>Manufacturing:</b>
            <span v-html="manufacturing"></span>
        </div>
        <div class="mb-3">
            <div class="table-responsive-xl">
                <table class="table"
                       style="width: 100%">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th class="text-center">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items">
                            <template v-if="item.parent">
                                <td colspan="999">
                                    <div class="d-flex gap-3 ps-4 ms-1">
                                        <img class="img-thumbnail object-fit-cover thumbnail-xs"
                                             :src="item.image ? item.image : partPlaceholderImage" />
                                        <div class="d-flex flex-column gap-1">
                                            {{ item.name }}
                                            <span v-if="item.sku">
                                                SKU: {{ item.sku }}
                                            </span>
                                            <span>Qty Per Kit: {{ item.quantity }}</span>
                                        </div>
                                    </div>
                                </td>
                            </template>
                            <template v-else>
                                <td>
                                    <div class="d-flex gap-3">
                                        <img class="img-thumbnail object-fit-cover thumbnail-sm"
                                             :src="item.image ? item.image : partPlaceholderImage"
                                             alt="Part Image"
                                        />
                                        <div class="d-flex flex-column gap-1">
                                            {{ item.name }}
                                            <span v-if="item.sku || item.newPartSKU">
                                                SKU: {{ item.sku ? item.sku : item.newPartSKU }}
                                            </span>
                                            <span class="text-muted">
                                                Used: {{ item.is_used ? 'Yes' : 'No' }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{ item.quantity }}</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p v-if="comments"
           class="text-prewrap">{{ comments }}</p>
    </div>
</template>

<script setup>
    import { ref, watch } from "vue";

    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });
    const partPlaceholderImage = window.placeholderImage;

    let items = ref([]);
    let comments = ref('');
    let customer = ref('');
    let manufacturing = ref('');

    watch(props.stepsData, () => onStepsDataChange());

    onStepsDataChange();
    function onStepsDataChange() {
        if (props.stepsData.items) {
            let mappedItems = [];
            props.stepsData.items.items.forEach(function (item) {
                item = JSON.parse(JSON.stringify(item));
                if (typeof item.part === 'string') {
                    item.name = item.part;
                    item.image = window.placeholderImage;
                    item.sku = null;
                } else {
                    let name = item.part.label.split('(');
                    item.name = name[0].trim();
                    item.sku = 1 in name ? name[1].replace(')', '') : null;
                    item.image = item.part.main_image ? item.part.main_image : window.placeholderImage;
                }
                mappedItems.push(item);
            });

            items.value = mappedItems;
            comments.value = props.stepsData.items.comments;
        }
        if (props.stepsData.customer) {
            let customerPreview = props.stepsData.customer.name;
            if (props.stepsData.customer.email) {
                customerPreview += `<a class="fs-sm fw-medium text-muted d-block" href="mailto:${props.stepsData.customer.email}"><i class="far fa-envelope"></i> ${props.stepsData.customer.email}</a>`
            }
            if (props.stepsData.customer.telephone) {
                customerPreview += `<a class="fs-sm fw-medium text-muted d-block" href="tel:${props.stepsData.customer.telephone}"><i class="fa fa-phone"></i> ${props.stepsData.customer.telephone}</a>`
            }
            customer.value = customerPreview;
        }
        if (props.stepsData.items.manufacturer) {
            manufacturing.value = props.stepsData.items.manufacturer.label;
        }
        if (props.stepsData.items.series) {
            manufacturing.value += ' > ' + props.stepsData.items.series.label;
        }
        if (props.stepsData.items.vehicle) {
            manufacturing.value += ' > ' + props.stepsData.items.vehicle.label;
        }
    }
</script>

<style lang="scss" scoped>
.totals {
    display: grid;
    justify-items: end;
    grid-template-columns: auto 175px;
    margin-left: 60%;
    gap: 1rem;

    .divider {
        grid-column: 1/3;
        height: 1px;
        width: 100%;
    }

    .total-value {
        padding-right: 0.75rem;
    }
}
</style>
