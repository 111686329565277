<template>
    <div class="mb-4 form-group">
        <label for="expiry_delay"
               class="control-label required">Currency</label>
        <formSelect v-model="currency"
                    :options="extras.currencies"
                    :url="routes.currencyUrl">
        </formSelect>
        <span class="text-muted"
              v-if="defaultCustomerCurrency && currency.code !== defaultCustomerCurrency">
            Customer's default currency is {{ defaultCustomerCurrency }}.
        </span>
    </div>
    <div class="mb-3">
        <div class="table-responsive-xl">
            <table class="table table-bordered table-striped table-vcenter">
                <thead>
                    <tr>
                        <th width="25%">Part</th>
                        <th class="text-center"
                            width="10%">Qty</th>
                        <th width="20%">Unit Price</th>
                        <th width="20%">VAT</th>
                        <th class="text-end">Line Price</th>
                        <th style="width:2%"></th>
                    </tr>
                </thead>
                <tbody>
                    <Item v-for="(item, index) in lines"
                          :index="index"
                          :item="item"
                          :currency="currency"
                          :partsUrl="routes.parts"
                          :taxRates="extras.taxRates"
                          :key="item.id"
                          ref="itemLinesRef"
                          @update:unitNet="(newValue) => item.unit_net = newValue"
                          @softDelete="(index, isSoftDeleted) => softDelete(index, isSoftDeleted)"
                          @removeItem="(index) => removeLine(index)">
                        <template v-if="item.is_delivery"
                                  #item-description>
                            <div v-if="suggestedDeliveryPrice != null"
                                 class="text-nowrap">
                                 Suggested: {{ currency.symbol }}{{ suggestedDeliveryPrice * currency.rate }}

                                <i v-if="suggestedDeliveryBreakdown != null"
                                   class="fas fa-info-circle swal-info"
                                   data-info-title="Delivery Breakdown"
                                   :data-info-html="suggestedDeliveryBreakdown"
                                   role="button"></i>
                            </div>
                            <div v-else>
                                Suggested: N/A
                            </div>
                        </template>
                    </Item>
                </tbody>
            </table>
        </div>
    </div>
    <button class="btn btn-sm btn-secondary mb-4"
            type="button"
            :disabled="isDeliveryLineSet"
            @click="addDelivery">
        Add Delivery Charge
    </button>
    <div class="form-group">
        <label for="comments"
               class="control-label">Comments</label>
        <textarea class="form-control"
                  name="comments"
                  rows="5"
                  v-model="comments"
                  id="comments">
        </textarea>
    </div>
</template>

<script setup>
    import { reactive, ref, watch, computed } from "vue";
    import debounce from 'lodash/debounce';
    import { useFetch } from "@/utilities/useFetch.js";
    import { priceFormat } from "@/utilities/priceFormat.js";
    import formSelect from '@/components/admin/utilities/formSelect.vue';
    import Item from "@/components/admin/quotes/pricing/item.vue";

    const emit = defineEmits(['stepValueChanged']);
    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });
    const lines = reactive(props.extras.preselected.items ? props.extras.preselected.items : []);
    const comments = ref(props.extras.preselected.comments ? props.extras.preselected.comments : '');
    const itemLinesRef = ref(null);
    const isDeliveryLineSet = ref(lines.filter((line) => line.is_delivery).length !== 0);

    const deliveryParts = computed(() => lines.map(line => { return { part: line.part?.value, quantity: line.quantity } }).filter(part => part.part));
    const suggestedDeliveryPrice = ref(null);
    const suggestedDeliveryTax = ref(null);
    const suggestedDeliveryBreakdown = ref(null);
    watch(deliveryParts, () => { updateSuggestedDeliveryPrice() });

    const defaultCustomerCurrency = ref(props.stepsData.customer.defaultCurrencyCode);
    const currency = ref({ label: 'GBP (1.0000)', code: 'GBP', rate: 1, symbol: '£' });
    if (props.extras.preselected.currency) {
        currency.value = props.extras.currencies.find(currency => currency.code === props.extras.preselected.currency);
    } else {
        if(defaultCustomerCurrency.value) {
            currency.value = props.extras.currencies.find(currency => currency.code === defaultCustomerCurrency.value);
        }
    }

    watch(
        () => ({ ...lines }),
        () => {
            updateParentWithValues();
            isDeliveryLineSet.value = lines.filter((line) => line.is_delivery).length !== 0;
        },
        { deep: true }
    );

    watch(currency, () => {
        updateParentWithValues();
    });


    watch(() => comments.value, () => updateParentWithValues());

    let fetchAbort, fetchAbortSignal;
    const updateSuggestedDeliveryPrice = debounce(async function () {
        const saveResponse = function (response = null) {
            if (typeof response === 'object' && response !== null && response.price_found) {
                suggestedDeliveryPrice.value = priceFormat(response.price.toFixed(2));
                suggestedDeliveryTax.value = response.tax;
                suggestedDeliveryBreakdown.value = `Address: ${response.address}<br/>Zone matched: ${response.delivery_zone}<br/>Band applied: ${response.delivery_band}`;
            } else {
                suggestedDeliveryPrice.value = null;
                suggestedDeliveryTax.value = null;
                suggestedDeliveryBreakdown.value = null;
            }
        }
        if (deliveryParts.value.length === 0 || props.routes.delivery_price === null || props.stepsData.customer.id === null) {
            saveResponse();
            return;
        }

        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        try {
            const res = await useFetch(props.routes.delivery_price, {
                signal: fetchAbortSignal,
                method: 'POST',
                body: {
                    'customer': props.stepsData.customer.id,
                    'parts': deliveryParts.value,
                }
            });

            saveResponse(await res.json());

        } catch (err) {
            saveResponse();
        }
    }, 750);

    function addDelivery() {
        if (!isDeliveryLineSet.value) {
            lines.push({
                id: Math.floor(Math.random() * Date.now()).toString(36),
                part: 'Delivery Charge',
                quantity: 1,
                is_delivery: true,
                unit_net: suggestedDeliveryPrice.value !== null ? suggestedDeliveryPrice.value : 0,
                tax: suggestedDeliveryTax.value !== null ? suggestedDeliveryTax.value : null,
            });
        }
    }

    function softDelete(index, isSoftDeleted) {
        lines[index].is_soft_deleted = isSoftDeleted;
    }

    function removeLine(index) {
        lines.splice(index, 1);
    }


    const updateParentWithValues = function () {
        let stepData = [];
        for (const [key, line] of Object.entries(lines)) {
            let lineData = JSON.parse(JSON.stringify(line));
            if ((!lineData.part && !lineData.is_delivery) || lineData.is_soft_deleted) {
                continue;
            }
            if (lineData.unit_net === null) {
                lineData.unit_net = 0;
            }
            stepData.push(lineData);
        }

        emit('stepValueChanged', {
            items: stepData,
            comments: comments.value,
            currency: currency.value,
        });
    }

    if (lines.length > 0 || comments.value) {
        updateParentWithValues();
    }
    updateSuggestedDeliveryPrice();
</script>
