<template>
    <div class="w-100 border border-light rounded">
        <div class="bg-body d-flex align-items-center">
            <div v-if="!previewMode"
                 class="form-check form-switch ms-3">
                <input class="form-check-input"
                       :middle="middle"
                       :checked="checked"
                       @change="(event) => setMainToggleState(event.currentTarget.checked)"
                       type="checkbox">
            </div>
            <div :class="{
                'flex-grow-1 d-flex justify-content-between align-items-center pe-3 py-2 toggle-header collapsed': true,
                'ps-3': previewMode,
            }"
                 data-bs-toggle="collapse"
                 :data-bs-target="'#content_' + uuid"
                 aria-expanded="false"
                 :aria-controls="'content_' + uuid"
                 role="button">
                <span>
                    {{ seriesTitle }}
                    ({{ currentlySelected }} <span v-if="!previewMode">/ {{ maxSelected }}</span> Selected)
                </span>
                <i class="fa"
                   aria-hidden="true"></i>
            </div>
        </div>
        <div class="collapse"
             :id="'content_' + uuid">
            <div class="px-3 py-2 d-flex flex-column gap-2">
                <vehicle v-for="vehicle in vehicles"
                         :title="vehicle.title"
                         :id="vehicle.id"
                         :preselected="preselectedVehicles.includes(parseInt(vehicle.id))"
                         :previewMode="previewMode"
                         @toggleChanged="vehicleToggleChanged"
                         ref="vehiclesRef"></vehicle>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch } from "vue";

    const emit = defineEmits(['threeStateToggleChanged']);

    const props = defineProps({
        vehicles: Array,
        preselectedVehicles: Array,
        seriesTitle: String,
        isOpen: {
            type: Boolean,
            default: false,
        },
        previewMode: {
            type: Boolean,
            default: false,
        }
    });

    const uuid = Math.floor(Math.random() * Date.now()).toString(36);
    const maxSelected = props.vehicles.length;
    const currentlySelected = ref(0);
    const vehiclesRef = ref(null);

    props.vehicles.forEach((vehicle) => {
        if (props.preselectedVehicles.includes(parseInt(vehicle.id))) {
            currentlySelected.value++;
        }
    });

    const checked = computed(() => currentlySelected.value > 0);
    const middle = computed(() => currentlySelected.value !== 0 && currentlySelected.value < maxSelected ? 1 : null);
    emit('threeStateToggleChanged', checked.value ? (middle.value ? 0 : 1) : -1);

    watch([checked, middle], ([newChecked, newMiddle]) => emit('threeStateToggleChanged', newChecked ? (newMiddle ? 0 : 1) : -1));

    const vehicleToggleChanged = function (isChecked) {
        currentlySelected.value += isChecked ? 1 : -1;
    }

    const setMainToggleState = function (checked) {
        vehiclesRef.value.forEach(function (vehicle) {
            vehicle.toggleState(checked);
        });
    }

    defineExpose({
        toggleState: setMainToggleState,
    });
</script>

<style lang="scss" scoped>
.toggle-header {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;

    i.fa::before {
        content: "\f077";
    }

    &.collapsed {
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;

        i.fa::before {
            content: "\f078";
        }
    }
}
</style>
