<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all delivery zones..">
                <span class="input-group-text bg-body border-0">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               refreshOn="delivery-zones-list:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="name">Name</sortableTh>
            <th>Countries</th>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="zone in data"
                :key="zone.id">
                <td>{{ zone.name }}</td>
                <td>{{ zone.countries }}</td>
                <td v-html="zone.actions"></td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import { ref } from "vue";

    const props = defineProps({
        ajaxUrl: String,
    });

    const orderBy = ref('name');
    const orderAsc = ref(true);
    const limit = ref(10);
    const searchTerm = ref(null);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };
</script>
