<template>
    <div v-if="!isLargeScreen && $slots.smallScreenLayout" ref="datatable">
        <div v-if="loading"
                class="text-center">
                Loading...
        </div>
        <div v-else-if="tableContents.value.data.length == 0"
            class="text-center">
                Nothing to show
        </div>
        <div v-else class="list-group">
            <slot name="smallScreenLayout" :data="tableContents.value.data"></slot>
        </div>
    </div>
    <div v-else class="table-responsive">
        <table class="table table-bordered table-striped table-vcenter"
               ref="datatable">
            <thead>
                <tr>
                    <slot name="headings"></slot>
                </tr>
            </thead>
            <tbody>
                <tr v-if="loading">
                    <td colspan="99"
                        class="text-center">Loading...</td>
                </tr>
                <tr v-else-if="tableContents.value.data.length == 0">
                    <td colspan="99"
                        class="text-center">Nothing to show</td>
                </tr>
                <slot v-else
                      name="rows"
                      :data="tableContents.value.data"></slot>
            </tbody>
        </table>
    </div>
    <div class="mt-2"
         v-if="!loading && tableContents.value.meta && tableContents.value.meta.links.length > 3">
        <ul class="pagination pagination-sm justify-content-end">
            <template v-for="(link, index) in tableContents.value.meta.links"
                      :key="index">
                <li v-if="link.url !== null || (index !== lastLink && index !== 0)"
                    class="page-item"
                    :class="{ active: link.active, ellipsis: link.url === null }">
                    <span v-if="link.url === null"
                          class="page-link"
                          v-html="link.label"></span>
                    <button v-else
                            class="page-link"
                            v-on:click.prevent="getData(link.url)"
                            v-html="link.label"></button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup>
    import { computed, reactive, ref, watch, onUpdated, nextTick } from "vue";
    import { useFetch } from "@/utilities/useFetch.js";
    import debounce from 'lodash/debounce';
    import { Eventbus } from '@/utilities/eventbus';

    const props = defineProps({
        limit: Number,
        orderBy: String,
        orderAsc: Boolean,
        search: String,
        url: String,
        refreshOn: String,
        customFilter: Object,
        static: {
            type: Boolean,
            default: false,
        },
    });

    const isLargeScreen = window.isLargeScreen;
    const tableContents = reactive({});
    const datatable = ref(null);

    // this only works if tableContents is reactive
    const lastLink = computed(() => tableContents.value.meta?.links.length - 1);

    Eventbus.on(props.refreshOn, () => getData());

    watch(
        () => props.search,
        () => getData(props.url), // reset url on search term change
    );
    watch(
        () => props.limit,
        () => getData(),
    );
    watch(
        () => props.orderAsc,
        () => getData(),
    );
    watch(
        () => props.orderBy,
        () => getData(),
    );
    if (props.customFilter) {
        watch(
            props.customFilter,
            () => getData(),
        );
    }

    let currentURL = null;

    const loading = ref(true);

    const originalQueryParams = (new URL(props.url)).searchParams;

    const getData = debounce(async function (url) {
        url = url || currentURL || props.url;
        if (currentURL !== url) {
            currentURL = url;
        }

        const urlToFetch = new URL(url);

        for (const [key, value] of originalQueryParams) {
            urlToFetch.searchParams.set(key, value);
        }

        if (props.search) {
            urlToFetch.searchParams.set('search', props.search);
        }

        if (props.limit) {
            urlToFetch.searchParams.set('limit', props.limit);
        }

        if (props.orderBy) {
            let orderBy = props.orderBy;
            if (props.orderAsc === false) {
                orderBy = `-${orderBy}`;
            }
            urlToFetch.searchParams.set('sort', orderBy);
        }

        if (props.customFilter) {
            for (const [key, value] of Object.entries(props.customFilter)) {
                urlToFetch.searchParams.set(key, value);
            }
        }

        loading.value = true;
        const res = await useFetch(urlToFetch);
        tableContents.value = await res.json();

        loading.value = false;

        nextTick(() => {
            if (datatable.value) {
                Eventbus.emit('datatable.updated', datatable.value);
            }
        });
    }, 500);

    onUpdated(() => {
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
    });

    if (props.static) {
        loading.value = false;
    } else {
        getData(props.url);
    }

    defineExpose({
        refresh: getData,
    });
</script>
