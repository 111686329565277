<template>
    <div v-if="tabData !== null">
        <div class="row items-push">
            <tile-detailed title="Quotes in Progress" :value="tabData.quotes_in_progress.count" button-text="View Quotes" :button-link="tabData.quotes_in_progress.route" icon="fa fa-file-lines" width="col-sm-6 col-xxl-4"></tile-detailed>
            <tile-detailed title="Unfinished Quotes" :value="tabData.requires_quoting.count" button-text="View Quotes" :button-link="tabData.requires_quoting.route" icon="fa fa-file" width="col-sm-6 col-xxl-4"></tile-detailed>
            <tile-detailed title="Quotes Expiring Soon" :value="tabData.quotes_expiring_soon.count" button-text="View Quotes" :button-link="tabData.quotes_expiring_soon.route" icon="fa fa-file-circle-exclamation" width="col-sm-6 col-xxl-4"></tile-detailed>
        </div>

        <div class="block block-rounded p-3">
            <LineChart :data="tabData.sales_chart" :options="chartOptions" />
        </div>

        <div class="block block-rounded">
            <ul class="nav nav-tabs nav-tabs-block" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'quotes_in_progress'
                    }" @click="currentTab = 'quotes_in_progress'" aria-selected="true">Quotes in Progress</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'requires_quoting'
                    }" @click="currentTab = 'requires_quoting'" aria-selected="true">Unfinished Quotes</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{
                        'active': currentTab === 'expiring_quotes'
                    }" @click="currentTab = 'expiring_quotes'" aria-selected="true">Quotes Expiring Soon</button>
                </li>
            </ul>
            <div class="block-content block-content-full">
                <QuotesTable v-if="currentTab === 'quotes_in_progress'" :ajax-url="tabData.quotes_in_progress.list_route" :columns="tabData.quotes_in_progress.list_columns"></QuotesTable>
                <QuotesTable v-if="currentTab === 'requires_quoting'" :ajax-url="tabData.requires_quoting.list_route" :columns="tabData.requires_quoting.list_columns"></QuotesTable>
                <QuotesTable v-if="currentTab === 'expiring_quotes'" :ajax-url="tabData.quotes_expiring_soon.list_route" :columns="tabData.quotes_expiring_soon.list_columns"></QuotesTable>
            </div>
        </div>
    </div>
    <div class="block-content text-center" v-else>
        <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

</template>
<script>

import {useFetch} from "../../../utilities/useFetch";
import debounce from "lodash/debounce";
import TileDetailed from "../utilities/oneui/tileDetailed.vue";
import SmallChart from "../utilities/oneui/smallChart.vue";
import { Line as LineChart }  from 'vue-chartjs';
import { Chart as ChartJS, registerables, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler } from 'chart.js'
import QuotesTable from "./components/quotesTable.vue";
ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler, ...registerables);

export default {
    components: {
        QuotesTable,
        SmallChart,
        TileDetailed,
        LineChart
    },

    data() {
        return {
            tabData: null,
            currentTab: 'requires_quoting',
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                aspectRatio: 4,
            }
        }
    },
    props: {
        'apiRoute': {
            type: String,
            default: 'none'
        }
    },
    mounted() {
        const component = this;
        const getTabData = debounce(async function () {
            let req = await useFetch(component.apiRoute);
            component.tabData = await req.json();

            return component.tabData;
        }, 500);
        getTabData();
    }
}
</script>
