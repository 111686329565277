<template>
    <div class="block block-rounded">
        <div class="block-header block-header-default d-flex">
            <h3 class="block-title">{{ title }}</h3>
        </div>
        <div class="block-content d-flex flex-column gap-2">
            <template v-if="items.length > 0">
                <table v-if="isLargeScreen"
                       class="table table-bordered table-striped table-vcenter items-table">
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th class="text-center">Qty</th>
                            <th class="text-center">{{ locationColumnHeader }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <listItem v-for="(item, i) in items"
                                  :key="i"
                                  @remove-picking="(uuid) => emit('remove-picking', uuid)"
                                  :item="item">
                        </listItem>
                    </tbody>
                </table>
                <div v-else>
                    <div class="d-flex flex-column gap-2 mb-4">
                        <listItem v-for="(item, i) in items"
                                  :key="i"
                                  @remove-picking="(uuid) => emit('remove-picking', uuid)"
                                  :item="item"
                                  :showBorder="i !== items.length - 1"
                                  mobileLayout>
                        </listItem>
                    </div>
                </div>
            </template>
            <p v-else
               class="text-center">{{ emptyMessage }}</p>
        </div>
    </div>
</template>

<script setup>
    import listItem from '@/components/admin/picking/alreadyPicked/listItem.vue'
    const emit = defineEmits(['remove-picking']);

    const props = defineProps({
        items: Array,
        wording: {
            type: String,
            default: 'picking',
            validator: (propValue) => {
                return ['picking', 'allocation'].includes(propValue);
            }
        }
    });
    const isLargeScreen = window.isLargeScreen;

    let title, emptyMessage, locationColumnHeader;
    if (props.wording === 'picking') {
        title = 'Picked';
        emptyMessage = 'You haven\'t picked any items yet.';
        locationColumnHeader = 'Picked From';
    } else {
        title = 'Allocated';
        emptyMessage = 'You haven\'t allocated any items yet.';
        locationColumnHeader = 'Allocated To';
    }

</script>
