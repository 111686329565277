<template>
    <div class="d-flex flex-column">
        <div class="w-100 d-flex gap-3 justify-content-center">
            <a v-if="isPdfReady && pdfUrl" :href="pdfUrl" target="_blank" class="option-container d-flex flex-column justify-content-center align-items-center border rounded p-3 mw-100">
                <i class="fa fa-print fs-1 mb-1"></i>
                <div class="text-uppercase fs-3 text-nowrap">
                    Packing Note
                </div>
            </a>
            <div v-else class="option-container d-flex flex-column justify-content-center align-items-center border rounded p-3 mw-100 disabled">
                <i class="fa fa-print fs-1 mb-1"></i>
                <div class="text-uppercase fs-3 text-nowrap">
                    Packing Note
                </div>
                <div class="text-muted">
                    <i class="fa fa-spin fa-spinner"></i> Please wait, generating PDF...
                </div>
            </div>
        </div>

        <div v-if="stepsData.pickings.address.country_code !== 'GB'">
            <h3 class="text-center mt-5">International Invoices</h3>
            <ul class="list-group w-25 mx-auto">
                <li v-for="pickings in props.stepsData.pickings.pickings" :key="pickings.id" class="list-group-item d-flex justify-content-between align-items-center">
                    Customer Order #{{ pickings.pickable }}
                    <a :href="routes.international_invoice.replace('%customer_order%', pickings.pickable)"
                       class="btn btn-sm btn-outline-primary"
                       data-bs-class="international-invoice-modal"
                       data-bs-backdrop="static"
                       ref="invoiceLinks">
                        <i class="fa fa-print"></i>
                        Print
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useFetch } from "@/utilities/useFetch.js";

const emit = defineEmits(['stepValueChanged']);

const props = defineProps({
    routes: Object,
    extras: Object,
    stepsData: Object,
});

const pdfUrl = ref(null);
const isPdfReady = ref(false);
const invoiceLinks = ref(null);

onMounted(() => {
    if (invoiceLinks.value) {
        invoiceLinks.value.forEach((link) => {
            link.ajaxModal();
        });
    }
});
async function saveDraft() {
    let stepsData = JSON.parse(JSON.stringify(props.stepsData));

    const res = await useFetch(props.routes.store_draft, {
        method: 'POST',
        body: {
            // Send only data needed for the packing note
            address: stepsData.pickings.address.value,
            pickings: Object.keys(stepsData.pickings.pickings),
        },
    });

    const result = await res.json();

    emit('stepValueChanged', {
        draftShipment: result.shipment,
    });
    pdfUrl.value = result.pdf_url;
    isPdfReady.value = true;
}
saveDraft();
</script>

<style lang="scss" scoped>
.option-container {
    color: #36474d;
    transition: all 0.2s;
    width: 300px;
    aspect-ratio: 1/1;
    scale: 1;

    &.disabled {
        opacity: 0.5;
    }
    &:not(.disabled):hover {
        color: #069fba;
        box-shadow: 0 0 1rem 3px rgba(6, 159, 186, 0.3);
        scale: 1.01;
        cursor: pointer;
    }
}
</style>
