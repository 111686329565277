<template>
    <tr>
        <td>
            <div class="d-flex gap-3">
                <img class="img-thumbnail object-fit-cover thumbnail-sm"
                     :src="image" />
                <div class="d-flex flex-column gap-1">
                    <div class="d-flex align-items-center gap-2">
                        <span>{{ item.name }}</span>
                        <partDrawer v-if="item.part" :sourceUrl="item.part.quick_view_url"></partDrawer>
                    </div>
                    <span :class="{
                        'text-muted': !overAllocating,
                        'text-danger': overAllocating
                    }">Unallocated qty: {{ item.unallocated_drop_ship_quantity }}</span>
                    <span v-if="item.sku"
                          class="text-muted">
                        SKU: {{ item.sku }}
                    </span>
                    <span v-if="!item.is_part_drop_shipped"
                          class="text-danger">
                        (This part is normally not drop shipped)
                    </span>
                </div>
            </div>
        </td>
        <td class="text-center quantity">
            <input v-if="isIncludedInDropShip"
                   class="form-control text-center m-auto qty-input"
                   :class="{
                        'is-invalid': overAllocating
                    }"
                   type="text"
                   inputmode="numeric"
                   pattern="[0-9]*"
                   placeholder="1"
                   v-model="item.quantity" />
            <span v-else>{{ item.quantity }}</span>
        </td>
        <td class="text-center">
            <i v-if="isIncludedInDropShip"
               class="fas fa-times text-danger"
               role="button"
               @click="() => { isIncludedInDropShip = false; emit('removeFromDropShip') }"></i>
            <i v-else
               class="fa fa-parachute-box text-success"
               role="button"
               @click="() => { isIncludedInDropShip = true; emit('addToDropShip') }"></i>
        </td>

    </tr>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import partDrawer from "@/components/admin/parts/partDrawer.vue";

    const emit = defineEmits(['removeFromDropShip', 'addToDropShip']);
    const props = defineProps({
        item: Object,
        index: [String, Number],
        partsUrl: String,
        isIncludedInDropShip: Boolean,
    });
    props.item.quantity = props.item.unallocated_drop_ship_quantity;

    const isIncludedInDropShip = ref(props.isIncludedInDropShip);

    const image = computed(() => {
        return props.item.part && props.item.part.main_image ? props.item.part.main_image : window.placeholderImage;
    });

    const overAllocating = computed(() => {
        return props.item.quantity > props.item.unallocated_drop_ship_quantity;
    });
</script>
