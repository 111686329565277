<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all pickings..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="picking-list:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="id">ID</sortableTh>
            <th>Items</th>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="order_id">Order</sortableTh>
            <th>Address</th>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="picker">Picked By</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="picked_at">Picked At</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="picking in data"
                :key="picking.id">
                <td v-html="picking.id"></td>
                <td>
                    <ul v-if="Array.isArray(picking.items) && picking.items.length > 0"
                        class="mb-0 ps-3">
                        <li v-for="(item, index) in picking.items.slice(0, itemsPreviewLimit)"
                            :key="index"
                            :class="{
                                'mb-2': index !== picking.items.slice(0, itemsPreviewLimit).length - 1
                            }">
                            <span class="me-2 d-inline-flex flex-wrap align-items-center column-gap-1">
                                <partDrawer v-if="item.quick_view_url" :sourceUrl="item.quick_view_url"></partDrawer>
                                <span>{{ item.part }}</span>
                                <span v-if="item.sku"
                                      class="fst-italic">({{ item.sku }})</span>
                            </span>
                            <br />
                            <span class="d-inline-block text-muted">Qty: {{ item.quantity }}</span>

                        </li>
                    </ul>
                    <div v-if="Array.isArray(picking.items) && picking.items.length > itemsPreviewLimit"
                         role="button"
                         @click="(event) => event.target.closest('tr').querySelector('.view-picking').click()"
                         class="ps-3 mt-1">
                        and {{ picking.items.length - itemsPreviewLimit }} more...
                    </div>
                </td>
                <td>
                    <span class="me-1" v-if="picking.quick_view_url">
                        <CustomerOrderDrawer :source-url="picking.quick_view_url"></CustomerOrderDrawer>
                    </span>
                    <span v-html="picking.order_id"></span>
                </td>
                <td v-html="picking.address"></td>
                <td>{{ picking.picker }}</td>
                <td>{{ picking.picked_at }}</td>
                <td v-html="picking.actions"></td>
            </tr>
        </template>
        <template #smallScreenLayout="{ data }">
            <div v-for="picking in data"
                :key="picking.id"
                class="list-group-item">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                        Picking {{ picking.id }}
                        <div class="fs-sm text-muted">
                            Order
                            <span class="me-1" v-if="picking.quick_view_url">
                                <CustomerOrderDrawer :source-url="picking.quick_view_url"></CustomerOrderDrawer>
                            </span>
                            <span v-html="picking.order_id"></span>
                        </div>
                    </h5>
                    <div>
                        <small class="d-block text-end">{{ picking.picked_at }}</small>
                        <small class="d-block text-end">{{ picking.picker }}</small>
                    </div>
                </div>
                <div v-if="Array.isArray(picking.items) && picking.items.length > 0">
                    <ul class="list-unstyled mt-2 mb-0">
                        <li v-for="(item, index) in picking.items.slice(0, itemsPreviewLimit)"
                            :key="index"
                            :class="{
                                'mb-2': index !== picking.items.slice(0, itemsPreviewLimit).length - 1
                            }">
                            <span class="d-inline-block me-2">
                                {{ item.part }}
                            </span>
                            <div class="d-flex justify-content-between">
                                <div v-if="item.sku"
                                    class="fst-italic">({{ item.sku }})</div>
                                <div class="text-muted">Qty: {{ item.quantity }}</div>
                            </div>

                        </li>
                    </ul>
                    <div v-if="picking.items.length > itemsPreviewLimit"
                        role="button"
                        @click="(event) => event.target.closest('.list-group-item').querySelector('.view-picking').click()"
                        class="text-center mt-1">
                        and {{ picking.items.length - itemsPreviewLimit }} more...
                    </div>
                </div>
                <div class="mt-2" v-html="picking.actions"></div>
            </div>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import partDrawer from '@/components/admin/parts/partDrawer.vue';
    import { ref, watch, reactive } from "vue";
    import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

    const emit = defineEmits(['addPick']);

    const props = defineProps({
        ajaxUrl: String,
        mustMatchPickings: Array,
    });

    const itemsPreviewLimit = 3;
    const orderBy = ref('id');
    const orderAsc = ref(false);
    const limit = ref(10);
    const searchTerm = ref(null);
    const customFilter = reactive({});

    watch(
        () => props.mustMatchPickings,
        () => filterChanged('must-match-pickings', props.mustMatchPickings),
        { immediate: true, deep: true }
    );

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };

    function filterChanged(key, value) {
        if ((typeof value === 'string' && value.length === 0) || customFilter[key] == value) {
            delete customFilter[key];
        } else {
            customFilter[key] = value;
        }
    }

    document.addEventListener('click', function (e) {
        if (e.target.matches('.add-to-pack, .add-to-pack *')) {
            emit('addPick', e.target.closest('.add-to-pack').dataset.pickId);
        }
    });
</script>
