<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <tableFilter label="Role"
                             :options="roles"
                             @change="(value, label) => filterChanged('role', value)"></tableFilter>
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all users..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="name">Name</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="email">Email</sortableTh>
            <th>Role</th>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="created_at">Created at</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="user in data"
                :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td v-html="user.role"></td>
                <td>{{ user.created_at }}</td>
                <td v-html="user.actions"></td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import tableFilter from '@/components/admin/datatable/tableFilter.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import { ref, reactive } from "vue";

    const props = defineProps({
        ajaxUrl: String,
        roles: {
            type: Object,
            default: {},
        },
    });

    const orderBy = ref('name');
    const orderAsc = ref(true);
    const limit = ref(10);
    const searchTerm = ref(null);
    const customFilter = reactive({});

    function filterChanged(key, value) {
        if (customFilter[key] == value) {
            delete customFilter[key];
        } else {
            customFilter[key] = value;
        }
    }

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };
</script>
