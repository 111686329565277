<template>
    <i class="text-primary"
       :class="{
           'fa fa-magnifying-glass': !isWaitingToOpen,
           'fa fa-spin fa-spinner': isWaitingToOpen,
       }"
       role="button"
       @click="openDrawer"></i>
    <drawer v-if="tractorPart"
            :title="tractorPart.part.name"
            size="lg"
            ref="drawerRef">
        <template #default>
            <div class="d-flex gap-3">
                <img v-if="mainImage" class="img-thumbnail thumbnail-md object-fit-cover"
                     :src="mainImage" />
                <div>
                    <div>{{ tractorPart.part.sku }}</div>
                    <div v-if="tractorPart.part.description"
                         class="text-muted ellipsis-2">
                        {{ tractorPart.part.description }}
                    </div>
                </div>
            </div>
            <div v-if="tractorPart.images && tractorPart.images.length > 1"
                 class="mt-3 w-100 d-flex gap-2 overflow-auto">
                <img class="d-inline-block img-thumbnail thumbnail-md object-fit-cover"
                       v-for="image in tractorPart.images"
                       :key="image"
                       :src="image" />
            </div>
            <dl class="mt-3 d-grid gap-3"
                style="grid-template-columns: 1fr 1fr;">
                <div class="dl-tile">
                    <dt>Tractor</dt>
                    <dd>{{ tractorPart.tractor.title }}</dd>
                </div>
                <!-- Spacer -->
                <div></div>
                <div v-if="tractorPart.location" class="dl-tile">
                    <dt>Location</dt>
                    <dd>{{ tractorPart.location }}</dd>
                </div>
                <div class="dl-tile">
                    <dt>Quantity</dt>
                    <dd>{{ tractorPart.quantity }}</dd>
                </div>
                <div v-if="tractorPart.dismantled_by" class="dl-tile">
                    <dt>Dismantled By</dt>
                    <dd>{{ tractorPart.dismantled_by }}</dd>
                </div>
                <div class="dl-tile">
                    <dt>Time Spent</dt>
                    <dd>{{ tractorPart.time_spent }}</dd>
                </div>
                <div class="dl-tile">
                    <dt>Dismantled</dt>
                    <dd>{{ tractorPart.created_at }}</dd>
                </div>
            </dl>
        </template>
    </drawer>
</template>

<script setup>
    import { ref, nextTick } from 'vue';
    import { useFetch } from "@/utilities/useFetch.js";
    import drawer from '@/components/admin/utilities/drawer.vue';

    const partPlaceholderImage = window.placeholderImage;

    const props = defineProps({
        partData: Object,
        sourceUrl: String,
    });

    const isWaitingToOpen = ref(false);
    const isLoading = ref(false);
    const hasFailed = ref(false);
    const drawerRef = ref(null);
    const tractorPart = ref(null);
    const mainImage = ref(null);

    const fetchData = async () => {
        if (isLoading.value || hasFailed.value) {
            return;
        }
        isLoading.value = true;
        const response = await useFetch(props.sourceUrl);
        if (!response.ok) {
            hasFailed.value = true;
            isLoading.value = false;
            return;
        }
        setTractorPart((await response.json()).data);
        isLoading.value = false;
        if (isWaitingToOpen.value) {
            isWaitingToOpen.value = false;
            nextTick(() => {
                // Need to be in nextTick because the drawerRef is not yet mounted as it depends on the v-if="part"
                openDrawer();
            });
        }
    };

    if (props.partData) {
        setTractorPart(props.partData);
    }

    const openDrawer = () => {
        if (!tractorPart.value) {
            isWaitingToOpen.value = true;
            fetchData();
            return;
        }

        drawerRef.value?.openDrawer();
    };

    const setTractorPart = (data) => {
        tractorPart.value = data;
        if (data.images && data.images.length > 0) {
            mainImage.value = data.images[0];
        } else if (data.part.main_image) {
            mainImage.value = data.part.main_image;
        } else {
            mainImage.value = partPlaceholderImage;
        }
    }
</script>
