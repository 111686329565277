<template>
    <formSelect v-model="apiAddressAutoComplete"
                :options="options"
                :search-callback="search"
                placeholder="Search for address..">
    </formSelect>
</template>

<script setup>
    import { ref, watch } from 'vue';
    import { useFetch } from "@/utilities/useFetch.js";
    import formSelect from '@/components/admin/utilities/formSelect.vue';

    const emit = defineEmits(['change']);

    const options = ref([]);
    const apiKey = window.addressApiKey;
    const apiAddressAutoComplete = ref(null);

    watch(apiAddressAutoComplete, (newVal) => {
        if (newVal && newVal.value) {
            getAddress(newVal.value);
        }
    });

    const search = async (val) => {
        if (!apiKey) {
            options.value = [];
            return;
        }

        // Input typed
        const res = await useFetch(`https://api.getaddress.io/autocomplete/${val}?api-key=${apiKey}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.status !== 200) {
            options.value = [];
            return;
        }

        options.value = (await res.json()).suggestions.map((s) => ({
            label: s.address,
            value: s.id,
        }));
    };

    const getAddress = async (id) => {

        const res = await useFetch(`https://api.getaddress.io/get/${id}?api-key=${apiKey}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const json = await res.json();

        let line3 = "";
        if (json.line_2.length && json.locality.length && !json.line_3.length) {
            line3 = json.locality;
        } else {
            line3 = json.line_3;
        }

        const address = {
            line_1: json.line_1,
            line_2: json.line_2.length ? json.line_2 : json.locality,
            line_3: line3,
            line_4: json.line_4,
            town: json.town_or_city,
            county: json.county,
            postcode: json.postcode,
            country: {
                value: 'GB',
                label: 'United Kingdom',
            },
        }

        emit('change', address);
    };

</script>
