import { useFetch } from '@/utilities/useFetch.js';
Element.prototype.ajaxModal = function (customOptions = {}) {
    let trigger = this;

    if (trigger.classList.contains('ajax-modal-init')) {
        return;
    }

    let dataOptions = {};

    if (trigger.nodeName === 'A' && trigger.getAttribute('href')) {
        dataOptions.url = trigger.getAttribute('href');
    }
    for (var key in trigger.dataset) {
        if (key.length > 2 && key.startsWith('bs') && key.charAt(2).toUpperCase() === key.charAt(2)) {
            let storeKey = key.substring(2);
            storeKey = storeKey.charAt(0).toLowerCase() + storeKey.slice(1);
            dataOptions[storeKey] = trigger.dataset[key];
        }
    }

    let options = {
        ...{
            shown: () => { },
            hidden: () => { },
            url: null,
        },
        ...dataOptions,
        ...customOptions
    };

    if (options.url == null) {
        console.error('Unable to init ajaxModal - options.url is not set');
        return;
    }

    trigger.classList.add('ajax-modal-init');

    trigger.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        loadModal();
    });

    const loadModal = async function () {
        let url = options.url;
        // Fetch latest href if there is one. This is to cater for dynamic links that get updated by JS
        if (trigger.nodeName === 'A' && trigger.getAttribute('href')) {
            url = trigger.getAttribute('href');
        }
        const response = await useFetch(url);

        const modalBody = await response.text();
        const uuid = Math.floor(Math.random() * Date.now()).toString(36);
        const customClass = options.class ? options.class : '';
        let html = `<div class="modal fade ${customClass}" id="${uuid}" tabindex="-1" style="display: none;" aria-hidden="true">
                        ${modalBody}
                    </div>`;

        document.getElementById('page-container').insertAdjacentHTML('beforeend', html);
        let modalDOM = document.getElementById(uuid);


        modalDOM.addEventListener('shown.bs.modal', function (event) {
            options.shown(event);
        })
        modalDOM.addEventListener('hidden.bs.modal', function (event) {
            options.hidden(event);
            modalDOM.remove();
        })

        let bsModal = new bootstrap.Modal(modalDOM, {
            backdrop: options.backdrop || true,
        });
        bsModal.show();
    };

}


