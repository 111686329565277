<template>
    <div v-if="!strippedVersion"
         class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <tableFilter label="Team"
                             :options="salesTeam"
                             :selected="selectedTeam"
                             @change="(value, label) => filterChanged('sales-team', value)"></tableFilter>
                <tableFilter label="Status"
                             :options="statuses"
                             :selected="selectedStatus"
                             @change="(value, label) => filterChanged('status', value)"></tableFilter>
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all customer orders..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               :customFilter="customFilter"
               refreshOn="customer-orders-list:refresh">
        <template #headings>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="id">ID</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="legacy_customer_order_id">Legacy ID</sortableTh>
            <sortableTh :currentOrderBy="orderBy">Invoice</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="customer">Customer</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="total">Total</sortableTh>
            <th class="text-center">Payment</th>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        width="15%"
                        orderBy="status"
                        class="text-center">Status</sortableTh>
            <sortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="placed_at"
                        class="text-center">Placed</sortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="order in data"
                :key="order.id">
                <td class="text-nowrap">
                    <CustomerOrderDrawer :source-url="order.quick_view_url"></CustomerOrderDrawer>
                    {{ order.id }}
                </td>
                <td>
                    <a v-if="order.legacy_customer_order_id && order.legacy_customer_order_link"
                       :href="order.legacy_customer_order_link"
                       target="_blank">{{ order.legacy_customer_order_id }}</a>
                </td>
                <td v-html="order.invoice"></td>
                <td v-html="order.customer"></td>
                <td>{{ order.total }}</td>
                <td class="text-center">
                    <template v-if="order.is_paid">
                        <i v-if="order.paid_at"
                           class="fa fa-check text-success"
                           role="button"
                           data-bs-toggle="popover"
                           data-bs-placement="top"
                           data-bs-trigger="hover"
                           data-bs-original-title="Payment Date"
                           :data-bs-content="order.paid_at"></i>
                        <i v-else
                           class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i v-if="order.payment_due_at"
                           class="far fa-calendar-days"
                           role="button"
                           data-bs-toggle="popover"
                           data-bs-placement="top"
                           data-bs-trigger="hover"
                           data-bs-original-title="Payment Due"
                           :data-bs-content="order.payment_due_at"></i>
                        <i v-else
                           class="fa fa-times"></i>
                    </template>
                </td>
                <td class="text-center"
                    v-html="order.status"></td>
                <td class="text-center">{{ order.placed_at }}</td>
                <td v-html="order.actions"></td>
            </tr>
        </template>
    </datatable>
</template>

<script setup>
    import datatable from '@/components/admin/utilities/datatable.vue';
    import tableFilter from '@/components/admin/datatable/tableFilter.vue';
    import sortableTh from '@/components/admin/datatable/sortableTh.vue';
    import { ref, reactive } from "vue";
    import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

    const props = defineProps({
        ajaxUrl: String,
        salesTeam: {
            type: Object,
            default: {},
        },
        statuses: {
            type: Object,
            default: {},
        },
        selectedTeam: {
            type: [String, Number],
            default: null,
        },
        selectedStatus: {
            type: [String, Number],
            default: null,
        },
        strippedVersion: {
            type: Boolean,
            default: false,
        },
    });

    const orderBy = ref('placed_at');
    const orderAsc = ref(false);
    const limit = ref(10);
    const searchTerm = ref(null);
    const customFilter = reactive({});
    filterChanged('sales-team', props.selectedTeam);
    filterChanged('status', props.selectedStatus);

    const setOrder = function (input) {
        if (orderBy.value == input) {
            orderAsc.value = !orderAsc.value;
        } else {
            orderBy.value = input;
            orderAsc.value = true;
        }
    };

    function filterChanged(key, value) {
        if (customFilter[key] == value) {
            delete customFilter[key];
        } else {
            customFilter[key] = value;
        }
    }
</script>
