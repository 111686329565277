<script setup>
import {debounce} from "lodash";
import {useFetch} from "@/utilities/useFetch.js";
import {useVModel} from "@vueuse/core";
import {ref} from "vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
    skuCheckUrl: {
        type: String,
        required: true,
    },
    itemSkus: {
        type: Array,
        default: () => [],
    },
    modelValue: {
        type: String,
        default: '',
    },
});

const model = useVModel(props, 'modelValue', emit);
const isSkuValid = ref(true);

const checkSku = debounce(async (sku) => {
    if (!sku) {
        model.value = '';
        isSkuValid.value = true;
        return;
    }

    if (props.itemSkus.includes(sku)) {
        model.value = '';
        isSkuValid.value = false;
        return;
    }

    const response = await useFetch(props.skuCheckUrl, {
        method: 'POST',
        body: {sku},
    });
    const data = await response.json();

    if (!data.valid) {
        model.value = '';
        isSkuValid.value = false;
        return;
    }

    model.value = sku;
    isSkuValid.value = true;
}, 500);
</script>

<template>
    <div class="form-group mt-2">
        <input type="text"
               class="form-control"
               :class="{'is-invalid': !isSkuValid}"
               placeholder="New part SKU"
               @input="checkSku($event.target.value)">
        <span v-if="!isSkuValid"
              class="invalid-feedback"
              role="alert">
            SKU is already in use.
        </span>
    </div>
</template>

<style scoped lang="scss">

</style>
