<template>
    <div v-if="Object.keys(selectedPicks).length > 0">
        <div class="row row-gap-2">
            <div class="col-lg-6 text-center text-lg-start"
                 v-if="customer">
                <h1 class="fs-lg mb-0">
                    <span>{{ customer.name }}</span>
                </h1>
                <a v-if="customer.email"
                   class="fs-sm fw-medium text-muted d-block"
                   :href="`mailto:${customer.email}`">
                    <i class="far fa-envelope"></i> {{ customer.email }}
                </a>
                <a v-if="customer.email"
                   class="fs-sm fw-medium text-muted d-block"
                   :href="`tel:${customer.telephone}`">
                    <i class="fa fa-phone"></i> {{ customer.telephone }}
                </a>
            </div>
            <div class="col-lg-6"
                 v-if="addressPreview">
                <div class="block block-rounded block-bordered">
                    <div class="block-header border-bottom">
                        <h3 class="block-title">Delivery Address</h3>
                    </div>
                    <div class="block-content">
                        <address class="fs-sm"
                                 v-html="addressPreview">
                        </address>
                    </div>
                </div>
            </div>
        </div>
        <selectedPickingList v-if="Object.keys(selectedPicks).length > 0"
                             :selected-pickings="selectedPicks"
                             @removeItem="(index) => removePick(index)"></selectedPickingList>
    </div>
    <h4 class="block-title mb-1">Available Pickings</h4>
    <div class="pb-2">
        <pickingList :ajax-url="routes.picking_list"
                     :must-match-pickings="mustMatchPickings"
                     @addPick="addPick"></pickingList>
    </div>
</template>

<script setup>
    import { ref, computed, reactive, watch } from "vue";
    import debounce from 'lodash/debounce';
    import pickingList from '@/components/admin/shipments/formWizard/pickingList.vue';
    import selectedPickingList from '@/components/admin/shipments/formWizard/selectedPickingList.vue';
    import { useFetch } from "@/utilities/useFetch.js";
    import { addressFormat } from "@/utilities/addressFormat.js";

    const emit = defineEmits(['stepValueChanged']);

    const props = defineProps({
        routes: Object,
        extras: Object,
        stepsData: Object,
    });

    // If there are preselected pickings, convert array of picking ids into an object where each id is a key
    const selectedPicks = reactive(
        Array.isArray(props.extras.preselected.pickings)
            ? props.extras.preselected.pickings.reduce((result, item) => ({ ...result, [item]: {} }), {})
            : {}
    );
    const mustMatchPickings = computed(() => Object.keys(selectedPicks));
    const address = ref(null);
    const customer = ref(null);
    const orderComments = {};

    const addressPreview = computed(() => address.value ? addressFormat(address.value) : null);
    const suggestedUnpickedOrders = ref([]);

    const addPick = function (pickId) {
        if (pickId in selectedPicks) {
            return;
        }

        selectedPicks[pickId] = {};
        debouncedAjaxPick(pickId);
    }

    const removePick = function (removeAtIndex) {
        let picksForSameOrder = 0;
        for (const key in selectedPicks) {
            if (selectedPicks[key].pickable === selectedPicks[removeAtIndex].pickable) {
                picksForSameOrder++;
            }
        }
        // If there is only one pick for the order, remove the order comment
        if (picksForSameOrder === 1) {
            removeOrderComment(selectedPicks[removeAtIndex].pickable);
        }

        delete selectedPicks[removeAtIndex];
        afterElementRemoved();
    }

    const afterElementRemoved = function () {
        if (Object.keys(selectedPicks).length === 0) {
            address.value = null;
            customer.value = null;
        }
    }

    const fetchOrderDetails = async function (customerOrderItemId) {
        if (address.value !== null && customer.value !== null) {
            return;
        }

        try {
            const res = await useFetch(props.routes.item_details.replace('%item%', customerOrderItemId));

            let data = await res.json();
            if (address.value === null) {
                address.value = data.delivery_address;
            }
            if (customer.value === null) {
                customer.value = data.customer;
            }

            if (data.comments) {
                addOrderComment(data.pickable, data.comments);
            }

            updateParentWithValues();
        } catch (err) {
            console.error(err);
        }
    }
    const debouncedFetchOrderDetails = debounce(fetchOrderDetails, 300);

    const ajaxPick = async function (pickId) {
        if (!props.routes.picking) {
            return;
        }

        try {
            const res = await useFetch(props.routes.picking.replace('%picking%', pickId));

            let response = await res.json();
            let data = response.data;

            if (address.value === null) {
                address.value = data.delivery_address;
            }
            if (customer.value === null) {
                customer.value = data.customer;
            }
            delete data.delivery_address;
            delete data.customer;
            delete data.supplier;
            selectedPicks[pickId] = data;

            if (data.comments) {
                addOrderComment(data.pickable, data.comments);
            }

            updateParentWithValues();
        } catch (err) {
            console.error(err);
        }
    };

    const updateParentWithValues = function () {
        emit('stepValueChanged', {
            pickings: selectedPicks,
            customer: customer.value,
            address: address.value,
        });
    }

    const debouncedAjaxPick = debounce(ajaxPick, 300);

    for (const key in selectedPicks) {
        ajaxPick(key);
    }

    const _renderComment = function (order, comment) {
        window.dispatchEvent(new CustomEvent('pushNotification', {
            detail: {
                icon: 'warning',
                text: `<b>Order #${order} Internal Comments</b>\n${comment}`,
            }
        }));
    }

    const addOrderComment = function (order, comment) {
        if (order in orderComments) {
            return;
        }
        orderComments[order] = comment;
        _renderComment(order, comment);
    }

    const removeOrderComment = function (order) {
        if (!orderComments.hasOwnProperty(order)) {
            return;
        }
        delete orderComments[order];

        // We can't clear individual notifications so we clear all notifications and re-add the ones that are still relevant
        window.dispatchEvent(new CustomEvent('clearNotifications'));
        for (const order in orderComments) {
            _renderComment(order, orderComments[order]);
        }
    }

    const ajaxSuggestedUnpickedOrders = async function (pickings) {
        if (pickings.length === 0) {
            suggestedUnpickedOrders.value = [];
            return;
        }

        try {
            let url = new URL(props.routes.suggested_unpicked_orders);
            url.searchParams.set('picking', pickings[0]);
            const res = await useFetch(url);
            let response = await res.json();
            if (response.data) {
                suggestedUnpickedOrders.value = response.data;
            } else {
                suggestedUnpickedOrders.value = [];
            }
        } catch (err) {
            console.error(err);
        }
    };

    const debouncedAjaxSuggestedUnpickedOrders = debounce(ajaxSuggestedUnpickedOrders, 300);

    watch(mustMatchPickings, (newValue, oldValue) => {
        if (newValue.length === 0) {
            suggestedUnpickedOrders.value = [];
            return;
        }
        if ((oldValue === undefined || oldValue.length === 0) && newValue.length > 0) {
            debouncedAjaxSuggestedUnpickedOrders(newValue);
        }
    }, { immediate: true });

</script>

<style lang="scss"
       scoped>
        @media (max-width: 767px) {
            ul.parts-list {
                list-style-type: none;
                padding: 0 !important;
            }
        }
    </style>
